import React, { useEffect, useState } from 'react';
import api from '../../utils/api';
import { useAuth } from '../../context/AuthContext';

const DocumentTypeOptions = () =>{
  const { token } = useAuth();

  const [documentTypes, setDocumentTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    (async ()=>{
      try{
        // const response = await api.get( `/document-type` );
        const response = await api.get(`/document-type`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          withCredentials: true,
        });

        setDocumentTypes(response.data);
        setIsLoading(false)
      }
      catch (e) {
        console.log(e);
      }
    })()

  }, []);


  return (
    <>
      {isLoading && <option disabled>Loading...</option>}
      {!isLoading && (
        <>
          <option disabled={true} value="">Select an option</option>
          {documentTypes.map( ( type ) => (
            <option key={ type.document_name } value={ type.document_name }>
              { type.document_name }
            </option>
          ) )}
        </>
      )}
    </>
  )
}

export default DocumentTypeOptions;
