import React from 'react';

export const Faq = () => {

  return (
    <>
      <div id={ `faq` } className="style-two my-20 mb-20">
        <div className="auto-container">
          <div className="row clearfix">


            <div className="form-column col-lg-8 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="accordian-box">
                  <h4 className={ `fw-bolder mb-3` }><b>Frequently Asked Questions</b></h4>

                  <ul className="accordion-box">
                    {/* Client Prospecting */ }
                    <li className="accordion block">
                      <div className="acc-btn active">
                        <div className="icon-outer">
                          <span className="icon icofont-plus"/>
                          <span className="icon icofont-minus"/>
                        </div>
                        Can I upgrade my plan?
                      </div>
                      <div className="acc-content" style={ { display: "block" } }>
                        <div className="content">
                          <div className="accordian-text">
                            "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
                            velit..."
                            "There is no one who loves pain itself, who seeks after it and wants to have it, simply
                            because it is pain..."
                          </div>
                        </div>
                      </div>
                    </li>

                    {/* Website Research */ }
                    <li className="accordion block">
                      <div className="acc-btn active">
                        <div className="icon-outer">
                          <span className="icon icofont-plus"/>
                          <span className="icon icofont-minus"/>
                        </div>
                        Which Plan is best for me?
                      </div>
                      <div className="acc-content" style={ { display: "block" } }>
                        <div className="content">
                          <div className="accordian-text">
                            "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
                            velit..."
                            "There is no one who loves pain itself, who seeks after it and wants to have it, simply
                            because it is pain..."
                          </div>
                        </div>
                      </div>
                    </li>

                    {/* Grant & Funding Research */ }
                    <li className="accordion block active-block">
                      <div className="acc-btn active">
                        <div className="icon-outer">
                          <span className="icon icofont-plus"/>
                          <span className="icon icofont-minus"/>
                        </div>
                        How do I pay?
                      </div>
                      <div className="acc-content" style={ { display: "block" } }>
                        <div className="content">
                          <div className="accordian-text">
                            "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
                            velit..."
                            "There is no one who loves pain itself, who seeks after it and wants to have it, simply
                            because it is pain..."
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>


            <div className="content-column col-lg-4 col-md-12 col-sm-12" style={ { paddingTop: '100px' } }>
              <div className="inner-column">
                <div className="sidebar-widget enquiry-widget">
                  <div className="widget-content">
                    <h4>For Enquiry</h4>
                    <div className="text">
                      You can also send us an email and we’ll get in touch shortly or Call
                    </div>
                    <div className="mb-10" style={ { color: 'white' } }>
                      Phone :<a href="tel:+1 226-975-4491" style={ { color: 'white' } }> +1 226-975-4491</a>
                    </div>
                    <a href="#contact" className="theme-btn btn-style-two">
                      <span className="txt">Send Email</span>
                    </a>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}
