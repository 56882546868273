import React from 'react';

export const Footer = () => {

  return (
    <>
      <footer className="main-footer">
        <div className="auto-container">
          {/* Widgets Section */ }
          <div className="widgets-section">
            <div className="row clearfix">

              {/* Column - Left */ }
              <div className="big-column col-lg-12 col-md-12 col-sm-6 col-12 text-center">
                <div className="row clearfix">

                  {/* Footer Column - Logo */ }
                  <div className="footer-column col-lg-4 col-md-6 col-sm-12">
                    <div className="footer-widget logo-widget">
                      <div className="logo">
                        <a href="#"><img src="images/logo.png" alt=""/></a>
                      </div>
                      <div className="call">
                        Call us directly
                        <a className="phone" href="tel: +1 226-975-4491"> +1 226-975-4491</a>
                        <a className="email" href="mailto:hello@docrenew.com">hello@docrenew.com</a>
                      </div>
                    </div>
                  </div>

                  {/* Footer Column - Company Links */ }
                  <div className="footer-column col-lg-4 col-md-6 col-sm-12">
                    <div className="footer-widget links-widget">
                      <h5>Company</h5>
                      <ul className="list-link">
                        <li><a href="#about">About Us</a></li>
                        <li><a href="#plans">Plans</a></li>
                      </ul>
                    </div>
                  </div>

                  {/* Footer Column - Quick Links */ }
                  <div className="footer-column col-lg-4 col-md-6 col-sm-12">
                    <div className="footer-widget links-widget">
                      <h5>Quick Links</h5>
                      <ul className="list-link">
                        <li><a href="#faq">FAQS</a></li>
                        <li><a href="#contact">Support</a></li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>

          {/* Footer Bottom */ }
          <div className="footer-bottom">
            <div className="auto-container">
              <div className="bottom-inner">
                <div className="row clearfix text-center">

                  {/* Copyright */ }
                  <div className="col-12">
                    <div className="copyright">Copyright ©
                      <script async="" src="#"></script>
                      <script>document.write(new Date().getFullYear());</script>
                      All rights reserved | <a
                        href="https://docrenew.com/" target="_blank">DocRenew</a>
                    </div>
                  </div>

                  {/* Social Links */ }
                  {/*<div className="col-lg-4 col-md-12 col-sm-12">*/ }
                  {/*  <ul className="social-nav">*/ }
                  {/*    <li><a href="https://twitter.com/" className="icofont-twitter"></a></li>*/ }
                  {/*    <li><a href="http://facebook.com/" className="icofont-facebook"></a></li>*/ }
                  {/*    <li><a href="https://www.instagram.com/" className="icofont-instagram"></a></li>*/ }
                  {/*    <li><a href="https://rss.com/" className="icofont-rss"></a></li>*/ }
                  {/*    <li><a href="https://www.youtube.com/" className="icofont-play-alt-1"></a></li>*/ }
                  {/*  </ul>*/ }
                  {/*</div>*/ }

                </div>
              </div>
            </div>
          </div>

        </div>
      </footer>
    </>
  );
}
