// api.js
import axios from 'axios';
import { toast } from "react-toastify";




// // let accessToken = null;
let accessToken = localStorage.getItem('token');
// console.log(accessToken);
// let refreshToken = null;

const liveURL =  'https://api.docrenew.com/api/v1';
const localURL =  'https://docrenew.local/api/v1';

const api = axios.create({
  baseURL: liveURL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // Automatically includes cookies in the request
});


api.interceptors.request.use(
  (config) => {

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => {
    // If the request succeeds, we don't have to do anything and just return the response
    return response
  },
  error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // We can check for the 401 status code and redirect the user to the login page

    // Get the current route
    const currentRoute = window.location.pathname;


    if (error.response && error.response.status === 401 && !currentRoute.includes('login')) {

      const userType = localStorage.getItem('userType')

      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('userType');
      localStorage.removeItem('userId');

      window.location.href = `/${userType.toLowerCase()}-login`; // replace '/login' with your login route
    }

    return Promise.reject(error);
  }
);


// api.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     const navigate = useNavigate(); // Access useNavigate hook within the interceptor
//
//     console.log(error.response.status);
//
//     if (error.response && error.response.status === 401) {
//       // Handle 401 Unauthorized errors
//       navigate('/login'); // Redirect to login page
//       return Promise.reject(error); // Reject the promise to signal error to caller
//     }
//
//     // Handle other errors accordingly
//     return Promise.reject(error);
//   }
// );

//
// api.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     if (error.response && error.response.status === 401) {
//     // if (1+1 === 2) {
//       alert('hey')
//       const { logout } = useAuth();
//
//       logout()
//
//
//       const navigate = useNavigate();
//       // Redirect to the login page when unauthenticated error is encountered
//       return navigate('/login');
//     }
//     return Promise.reject(error);
//   }
// );



//
// api.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     const originalRequest = error.config;
//
//     // If the error status is 401 and the original request was not a token refresh
//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//
//       // Refresh the access token using the refresh token
//       try {
//         const response = await axios.post(
//           'https://your-api-base-url.com/api/refresh',
//           {
//             refresh_token: refreshToken,
//           }
//         );
//
//         accessToken = response.data.access_token;
//
//         // Retry the original request with the new access token
//         originalRequest.headers.Authorization = `Bearer ${accessToken}`;
//         return axios(originalRequest);
//       } catch (refreshError) {
//         // If the refresh token is expired or refresh fails, redirect to log in or handle accordingly
//         console.error('Refresh token failed', refreshError);
//         // Redirect to log in or handle the error appropriately
//         throw refreshError;
//       }
//     }
//
//     return Promise.reject(error);
//   }
// );

// export const setTokens = (newAccessToken, newRefreshToken) => {
//   accessToken = newAccessToken;
//   refreshToken = newRefreshToken;
// };

export default api;
