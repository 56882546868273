import React, { createContext, useContext, useState } from 'react';
import api from '../utils/api';

const AuthContext = createContext({});


export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [user, setUser] = useState(localStorage.getItem('user') || null);
  const [client, setClient] = useState(null);
  const [userType, setUserType] = useState(localStorage.getItem('userType') || '');
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true); // Add this line


  const login = async (data) => {
    // Store the token, user, and userType in sessionStorage
    await localStorage.setItem('token', data.token);
    await localStorage.setItem('user', JSON.stringify(data.user));
    await localStorage.setItem('userType', data.userType);

    await setToken(data.token);
    await setUser(data.user);
    await setClient(data.user);
    await setUserType(data.userType);

    if ( data.userType === 'Individual' ) {
      // await setClientData(data.user);
      await localStorage.setItem('userId', data.user.client_id);
    } else if ( data.userType === 'Corporate' ) {
      await localStorage.setItem('userId', data.user.organization_id);
      // await setOrganizationData(data.user);
    } else {
      await localStorage.setItem('userId', data.user.admin_id);
      // await setAdminData(data.user);
    }


  };

  const logout = async () => {
    let oldUserType;
    let oldToken = localStorage.getItem('token');

    // Remove the token, user, and userType from sessionStorage
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('userType');
    localStorage.removeItem('userId');

    if (userType === 'Individual') {
      oldUserType = 'client';
    } else if (userType === 'Corporate') {
      oldUserType = 'organization';
    } else {
      oldUserType = 'admin';
    }

    setToken('');
    setUser(null);
    setClient(null);
    setUserType('');
    // setClientData(null)
    // setOrganizationData(null)
    // setAdminData(null)

    await api.get(`/authentication/${oldUserType}/logout`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${oldToken}`
      },
      withCredentials: true,
    });
  };

  const isAuthenticated = () => {
    return !!token;
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, client, user, token, login, logout, userType, pageIsLoading, setPageIsLoading, isLoading, setIsLoading }}>
      {children}
    </AuthContext.Provider>
  );
};


export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
