import React, {useEffect, useRef, useState} from "react";
import { Modal, Button, Container } from 'react-bootstrap';
import moment from 'moment';
import Swal from 'sweetalert2';
import api from '../utils/api';

import { Header } from '../components/AdminPage/Header';
import PaystackPop from '@paystack/inline-js';
import { openPaystack, verifyTransaction } from '../utils/paystackFunction';
import PageLoader from '../components/AdminPage/PageLoader';
import { useAuth } from '../context/AuthContext';
import { Footer } from '../components/AdminPage/Footer';
import { Navigate } from 'react-router-dom';

const CorporatePage = () => {

  const { token, userType, isLoading, setIsLoading, pageIsLoading, setPageIsLoading} = useAuth();

  const [show, setShow] = useState(false);
  const [showPayModal, setShowPayModal] = useState(false);
  const [showBulkUpload, setShowBulkUpload] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subscriptionAmount, setSubscriptionAmount] = useState(0);
  const [subscriptionPlan, setSubscriptionPlan] = useState('');
  const [paymentGateway, setPaymentGateway] = useState('PayStack');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [organizationData, setOrganizationData] = useState({}); // Add this line

  const submitSingleButtonRef = useRef(null);
  const submitBulkButtonRef = useRef(null);

  const organizationId = localStorage.getItem('userId');

  const initialProfileFormData = {
    name: '',
    email: '',
    phone: '',
  };

  const [newProfileFormData, setNewProfileFormData] = useState(initialProfileFormData);

  const [dataLoaded, setDataLoaded] = useState(false); // Add this line
  const [file, setFile] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (organizationId) {
        await getOrganizationData();
      }
      setIsLoading(false);
      setDataLoaded(true); // Set dataLoaded to true after data is fetched
    };

    if (organizationId) {
      fetchData().then();
    } else {
      setPageIsLoading(false);
    }

  }, []);

  const getOrganizationData = async () => {

    setIsLoading(true);

    try {
      const responseData = await api.get(`/organizations/${organizationId}/details`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        withCredentials: true,
      });

      await setOrganizationData(responseData.data);
    } catch (error) {
      console.error('Login failed', error.message);
    } finally {
      setIsLoading(false);
      setPageIsLoading(false);
    }
  };

  const handleSubmitNewProfile = async (e) => {
    e.preventDefault();

    if (confirmEmail !== newProfileFormData.email) {
      Swal.fire({
        title: "Error",
        text: `Email doesn't match`,
        allowOutsideClick: false,
        icon: "error",
        showConfirmButton: true,
        customClass: {
          confirmButton: "btn btn-danger"
        }
      });
      return false;
    }

    try {
      let data = JSON.stringify({...newProfileFormData, organization_id: organizationId});

      console.log(data);

      submitSingleButtonRef.current.setAttribute('data-kt-indicator', 'on');
      submitSingleButtonRef.current.disabled = true;
      setIsSubmitting(true);

      // await api.post(`/organization/new-individual-profile`, data);
      await api.post(`/organizations/${organizationId}/new-client`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        withCredentials: true,
      });

      handleClose();
      getOrganizationData().then();
      setNewProfileFormData(initialProfileFormData);
      setConfirmEmail('');

      // Swal.fire({
      //   title: "New Profile added successfully!",
      //   text: "Success.",
      //   allowOutsideClick: false,
      //   icon: "success",
      //   showConfirmButton: true,
      //   customClass: {
      //     confirmButton: "btn btn-info"
      //   }
      // });

    } catch (e) {
      Swal.fire({
        title: "Error",
        text: e.response.data.message,
        allowOutsideClick: false,
        icon: "error",
        showConfirmButton: true,
        customClass: {
          confirmButton: "btn btn-danger"
        }
      });
    } finally {
      submitSingleButtonRef.current.removeAttribute('data-kt-indicator');
      submitSingleButtonRef.current.disabled = false;
      setIsSubmitting(false);
    }
  };

  const handleBulkUpload = async (e) => {
    e.preventDefault();

    submitBulkButtonRef.current.setAttribute('data-kt-indicator', 'on');
    submitBulkButtonRef.current.disabled = true;

    if (!file) {
      Swal.fire({
        title: "Error",
        text: `No file selected`,
        allowOutsideClick: false,
        icon: "error",
        showConfirmButton: true,
        customClass: {
          confirmButton: "btn btn-danger"
        }
      });
      return false;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('organization_id', organizationId);

    try {
      setIsSubmitting(true);

      const response = await api.post(`/organizations/${organizationId}/bulk-new-client`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        },
        withCredentials: true,
      });

      handleCloseBulkModal();
      getOrganizationData().then();
      setFile(null);

      await Swal.fire({
        title: `${response.data.message}`,
        text: "Success.",
        allowOutsideClick: false,
        icon: "success",
        showConfirmButton: true,
        customClass: {
          confirmButton: "btn btn-info"
        }
      });

    } catch (e) {
      Swal.fire({
        title: "Error",
        text: e.response.data.message,
        allowOutsideClick: false,
        icon: "error",
        showConfirmButton: true,
        customClass: {
          confirmButton: "btn btn-danger"
        }
      });
    } finally {
      submitBulkButtonRef.current.removeAttribute('data-kt-indicator');
      submitBulkButtonRef.current.disabled = false;
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    setShowPayModal(false);
    setSubscriptionAmount(0);
    setSubscriptionPlan('');
  };

  const handleClose = () => setShow(false);

  const handleShow = () => {

    if (dataLoaded && organizationData.subscriptions && organizationData.subscriptions.length === 0) {
      Swal.fire( {
        title: "No Subscription Found!",
        text: `Select a subscription plan to continue`,
        allowOutsideClick: false,
        // toast: true,
        icon: "error",
        showConfirmButton: true,
        customClass: {
          confirmButton: "btn btn-primary"
        }
        // timer: 2000,
        // timerProgressBar: true,
      } );
    } else {
      setShow( true )
    }

  };

  const handleShowBulkModal = () => setShowBulkUpload( true );

  const handleCloseBulkModal = () => setShowBulkUpload(false);

  const handleMakePayment = async (data) => {
    try {

      if ( paymentGateway === 'PayStack' ) {
        const transactionReference = await openPaystack(data);

        if ( transactionReference === 'failed' ) throw new Error('Transaction not found');

        const transactionResponse = await verifyTransaction(transactionReference);

        if ( transactionResponse !== 'success' ) throw new Error('Transaction verification failed');

        await getOrganizationData().then();
      }

    } catch ( e ) {
      Swal.fire( {
        title: "Payment Failed!",
        text: `Error Processing Payment`,
        allowOutsideClick: false,
        // toast: true,
        icon: "error",
        showConfirmButton: true,
        customClass: {
          confirmButton: "btn btn-primary"
        }
        // timer: 2000,
        // timerProgressBar: true,
      } );
    }



  }

  const verifyTransaction = async (transactionReference) => {
    try {
      // const verificationStatus =  await api.get( `/paystack/transaction/verify/${ transactionReference }` );
      const verificationStatus = await api.get(`/paystack/transaction/verify/${ transactionReference }`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        withCredentials: true,
      });

      return verificationStatus.data.status;

    } catch ( e ) {
      return 'failed';
    }

  }

  const openPaystackModal = async (e) => {

    e.preventDefault();


    if ( subscriptionAmount === 0 ) {
      Swal.fire( {
        title: "Select a Subscription Plan!",
        text: `No subscription was selected`,
        allowOutsideClick: false,
        // toast: true,
        icon: "error",
        showConfirmButton: true,
        customClass: {
          confirmButton: "btn btn-primary"
        }
        // timer: 2000,
        // timerProgressBar: true,
      } );

      return false;
    }


    const paystack = new PaystackPop();

    await paystack.newTransaction({
      key: 'pk_test_46f30d9bbce30b16d621c531e7badec32b6b55d7',
      email: organizationData.email,
      amount: subscriptionAmount * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
      currency: 'NGN',
      metadata: {
        name: organizationData.name,
        phone: organizationData.phone,
        subscriber_id: organizationData.organization_id,
        subscriber_type: "Organization",
        package_name: subscriptionPlan,
        start_date: moment().format( "YYYY-MM-DD" ),
        end_date: moment().add(30, 'days').format('YYYY-MM-DD'),
      },
      onSuccess: (transaction) => {

        setShowPayModal(false);
        setIsLoading(true);
        setSubscriptionAmount(0);
        setSubscriptionPlan('');

        setTimeout(async ()=>{
          await verifyTransaction( transaction.reference );

          await getOrganizationData()
        }, 8000)


        // Payment complete! Reference: transaction.reference
      },
      onCancel: () => {
        // user closed popup

      }

    });
  }

  if ( !token && userType !== 'Corporate' ) return <Navigate to={`/corporate-login`} />;

  let countProfiles = 0;

  return (
    <>
      {
        pageIsLoading
          ?
          <PageLoader />
          :
          <Container fluid>
            <div className="app-main flex-column flex-row-fluid" id="kt_app_main">


              <div className="d-flex flex-column flex-column-fluid">

                <Header clientType={ `Corporate` }/>


                {/* Begin Content */}
                <div id="" className="app-content flex-column-fluid">

                  <div className="container">
                    <div className="row h-100">
                      {/* Begin Sidebar */ }
                      <div className="col-lg-4 d-flex flex-column h-100">

                        {/* Begin Card */ }
                        <div className="card shadow-sm">
                          {/* Card header */ }
                          <div className="card-header">
                            {/* Card title */ }
                            <div className="card-title">
                              <h5 className={ `fw-bold` }>Account Details</h5>
                            </div>
                          </div>
                          {/* Card header */ }

                          <div className="card-body pt-0 fs-6">
                            {/* Section */ }
                            <div className="mb-7">
                              {/* Details */ }
                              <div className="d-flex align-items-center">
                                <div className="d-flex flex-column">
                                  {/* Name */ }
                                  <a href="#"
                                     className="fs-4 fw-bold text-gray-900 text-hover-primary me-2"
                                  >
                                    { organizationData.name }
                                  </a>
                                  {/* Email */ }
                                  <a href="#"
                                     className="fw-semibold text-gray-600 text-hover-primary"
                                  >
                                    Email: <b>{ organizationData.email }</b>
                                  </a>
                                  <a href="#"
                                     className="fw-semibold text-gray-600 text-hover-primary"
                                  >
                                    Phone: <b>{ organizationData.phone }</b>
                                  </a>
                                  <a href="#"
                                     className="fw-semibold text-gray-600 text-hover-primary"
                                  >
                                    Contact Person: <b>{ organizationData.contact_person }</b>
                                  </a>

                                  {/*<Button*/}
                                  {/*  className="btn btn-sm btn-light-success me-2 fw-bold indication mt-5"*/}
                                  {/*  variant="success"*/}
                                  {/*  onClick={ getOrganizationData }*/}
                                  {/*>*/}
                                  {/*  <span className="indicator-label">Refresh Data</span>*/}

                                  {/*</Button>*/}

                                  <Button
                                    className="btn btn-sm btn-light-primary me-2 fw-bold indication mt-5"
                                    variant="primary"
                                    onClick={ getOrganizationData }
                                  >
                                    <span className="indicator-label">Check Subscription Status</span>

                                  </Button>
                                </div>
                                {/* Info */ }

                              </div>
                              {/* Details */ }
                            </div>
                            {/* Section */ }

                            {/* Separator */ }
                            <div className="separator separator-dashed mb-7"></div>
                            {/* Separator */ }

                            {
                              !isLoading && dataLoaded
                                ?
                                <>
                                  {/* Section */ }
                                  <div className="mb-10">

                                    {
                                      // !dataLoaded && organizationData.subscriptions.length === 0
                                      organizationData.subscriptions.length === 0
                                        ?
                                        <h5 className="mb-4">No Subscription Found!</h5>
                                        :
                                        <>
                                          {/* Title */}
                                          <h5 className="mb-4">Subscription Details</h5>

                                          {/* Details */}
                                          <table
                                            className="table table-row-dashed table-row-gray-300 fs-6 fw-semibold gs-0 gy-2 gx-0">

                                            <tbody>

                                            {/* Row */}
                                            <tr className="">
                                              <td className="text-gray-500">Status:</td>
                                              <td>
                                                {
                                                  organizationData.subscriptions.length === 0
                                                    ?
                                                    <span className="badge badge-light-warning">Not Active</span>
                                                    :
                                                    moment().isAfter(moment(organizationData.subscriptions[0].end_date, 'YYYY-MM-DD'))
                                                      ?
                                                      <span className="badge badge-light-danger">Expired</span>
                                                      :
                                                      <span className="badge badge-light-success">Active</span>
                                                }

                                              </td>
                                            </tr>
                                            {/* Row */}
                                            <tr className="">
                                              <td className="text-gray-500">Package:</td>
                                              <td className="text-gray-800">
                                                {
                                                  organizationData.subscriptions.length === 0
                                                    ?
                                                    "Not available"
                                                    :
                                                    organizationData.subscriptions[0].package_name
                                                }
                                              </td>
                                            </tr>
                                            {/* Row */}
                                            <tr className="">
                                              <td className="text-gray-500">Started:</td>
                                              <td
                                                className="text-gray-800">
                                                {
                                                  organizationData.subscriptions.length === 0
                                                    ?
                                                    "Not available"
                                                    :
                                                    moment(organizationData.subscriptions[0].start_date).format("ddd, MMM Do YYYY")
                                                }
                                              </td>
                                            </tr>
                                            {/* Row */}
                                            <tr className="">
                                              <td className="text-gray-500">Expires:</td>
                                              <td
                                                className="text-gray-800">
                                                {
                                                  organizationData.subscriptions.length === 0
                                                    ?
                                                    "Not available"
                                                    :
                                                    moment(organizationData.subscriptions[0].end_date).format("ddd, MMM Do YYYY")
                                                }
                                              </td>
                                            </tr>
                                            {/* Row */}
                                            {/*<tr className="">*/}
                                            {/*  <td className="text-gray-500">Next Renewal:</td>*/}
                                            {/*  <td*/}
                                            {/*    className="text-gray-800">*/}
                                            {/*    {*/}
                                            {/*      organizationData.subscriptions.length === 0*/}
                                            {/*        ?*/}
                                            {/*        "Not available"*/}
                                            {/*        :*/}
                                            {/*        moment(organizationData.subscriptions[0].end_date).add(1, 'day').format("ddd, MMM Do YYYY")*/}
                                            {/*    }*/}
                                            {/*  </td>*/}
                                            {/*</tr>*/}
                                            {/* Row */}
                                            {/*<tr className="">*/}
                                            {/*  <td className="text-gray-500">Remaining Credit:</td>*/}
                                            {/*  <td className="text-gray-800 fw-bolder fs-5">{ 50 }</td>*/}
                                            {/*</tr>*/}


                                            </tbody>
                                          </table>
                                          {/* Details */}

                                        </>

                                    }

                                    {
                                      organizationData.subscriptions.length === 0 || moment().isAfter( moment( organizationData.subscriptions[0].end_date, 'YYYY-MM-DD' ) )
                                        ?
                                        <div className="mb-0">
                                          <button
                                            className="btn btn-flex btn-light-primary fw-bold"
                                            // onClick={ () => openPaystack() }
                                            onClick={ () => setShowPayModal( true ) }
                                          >
                                            Add/Upgrade Subscription
                                          </button>

                                        </div>
                                        :
                                        <div className="mb-0">
                                          <button
                                            className="btn btn-success fw-bold btn-flex btn-light-success"
                                          >
                                            Subscription Active
                                          </button>
                                        </div>
                                    }


                                  </div>
                                </>
                                :

                                <div className="card-body d-flex align-items-center justify-content-center text-muted">
                                    <span className="spinner-border text-primary mx-3" role="status"
                                          aria-hidden="true"></span>
                                  Loading...
                                </div>

                            }

                          </div>
                        </div>
                        {/* End Card */ }

                      </div>
                      {/* End Sidebar */ }

                      {/* Begin Table */ }
                      <div className="col-lg-8 d-flex flex-column h-100">

                        {/* Begin Card */ }
                        <div className="card shadow-sm">

                          {/* Card Header */ }
                          <div className="card-header border-0">
                            {/* Card Title */ }
                            <div className="card-title">
                              <h5 className={ `fw-bold` }>Uploaded Profiles</h5>
                            </div>
                            {/* End Card Title */ }
                            {/* Toolbar */ }
                            <div className="card-toolbar m-0">
                              {
                                !isLoading && dataLoaded && organizationData.subscriptions.length !== 0 &&
                                !moment().isAfter( moment( organizationData?.subscriptions[0].end_date, 'YYYY-MM-DD' )) &&
                                <>
                                  <a href="#" className="btn btn-sm btn-flex btn-light-primary fw-bold me-5"
                                     onClick={handleShow}>
                                    <i className="ki-duotone ki-plus-square fs-3">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                      <span className="path3"></span>
                                    </i> Add New Profile
                                  </a>

                                  <a href="#" className="btn btn-sm btn-flex btn-light-primary fw-bold me-5"
                                     onClick={handleShowBulkModal}>
                                    <i className="ki-duotone ki-upload fs-3">
                                      <span className="path1"></span>
                                      <span className="path2"></span>
                                      <span className="path3"></span>
                                    </i> Bulk Upload
                                  </a>
                                </>
                              }

                            </div>
                            {/* End Toolbar */}
                          </div>
                          {/* End Card Header */}

                          {/* Card Body */}
                          <div className="card-body pt-0">

                            <div className={`table-responsive`}>
                              {/* Begin Table */}
                              <table id="" className="table align-middle table-row-dashed fs-6 fw-bold gy-2">
                                {/* Table Header */}
                                <thead className="border-bottom border-gray-200 fs-7 text-uppercase fw-bold">
                                <tr className="text-start text-muted gs-0">
                                  <th className="min-w-50px">S/N</th>
                                  <th className="min-w-200px">Client Name</th>
                                  <th className="min-w-100px">Email</th>
                                  <th className="min-w-100px">Phone</th>
                                  {/*<th className="min-w-100px">Expiry Date</th>*/ }
                                  {/*<th className="min-w-100px text-end pe-7">Update</th>*/ }
                                </tr>
                                </thead>
                                {/* Table Body */ }
                                <tbody className="fs-5 fw-semibold text-gray-600">


                                {
                                  !isLoading && dataLoaded && organizationData.clients.length !== 0
                                    ?
                                    (organizationData.clients.map( ( clients ) => {

                                      // setCountDocuments(countProfiles++);
                                      countProfiles++;

                                      return <tr key={ clients.client_id }>
                                        <td>
                                          <a href="#" className="text-gray-600 text-hover-primary">{ countProfiles }</a>
                                        </td>
                                        <td className="text-muted">{ clients.name }</td>
                                        <td className="text-muted">{ clients.email }</td>
                                        <td className="text-muted">{ clients.phone }</td>
                                      </tr>;
                                    } ))
                                    :

                                    isLoading && dataLoaded
                                      ?
                                      <tr>
                                        <td colSpan={ 5 } className={ `text-center` }>
                                          <div
                                            className="card-body d-flex align-items-center justify-content-center text-muted">
                                              <span className="spinner-border text-primary mx-3" role="status"
                                                    aria-hidden="true"></span>
                                            Loading...
                                          </div>
                                        </td>
                                      </tr>
                                      :
                                      <tr>
                                        <td colSpan={ 5 } className={ `text-center` }>
                                          <span className="text-gray-600 text-hover-primary">No uploaded profile</span>
                                        </td>
                                      </tr>
                                }
                                </tbody>
                              </table>
                              {/* End Table */ }
                            </div>


                          </div>
                          {/* End Card Body */ }

                        </div>
                        {/* End Card */ }

                      </div>
                      {/* End Table */ }
                    </div>
                  </div>

                </div>
                {/* End Content */ }

                <Modal
                  className=""
                  show={ showPayModal }
                  onHide={ handleClose }
                  // centered
                  size="md"
                  backdrop="static"
                  keyboard={ false }
                >
                  <Modal.Body>

                    <form className="form fv-plugins-bootstrap5 fv-plugins-framework">
                      {/* Heading */ }
                      <div className="mb-13 text-center">
                        <h5 className="mb-3"><b>Select A Plan</b></h5>
                      </div>

                      <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">

                        <div className="d-flex flex-stack gap-5 mb-3">

                          <button
                            type="button"
                            className="btn btn-light-primary w-100"
                            onClick={ () => {
                              setSubscriptionAmount( 10 );
                              setSubscriptionPlan( 'Bronze' )
                            }}
                          >
                            <b>Bronze</b> <br/>50 Profiles
                          </button>
                          <button
                            type="button"
                            className="btn btn-light-primary w-100"
                            onClick={ () => {
                              setSubscriptionAmount( 50 );
                              setSubscriptionPlan( 'Silver' )
                            }}
                          >
                            <b>Silver</b> <br/>100 Profiles
                          </button>
                          <button
                            type="button"
                            className="btn btn-light-primary w-100"
                            onClick={ () => {
                              setSubscriptionAmount( 100 );
                              setSubscriptionPlan( 'Gold' )
                            }}
                          >
                            <b>Gold</b> <br/>500 Profiles
                          </button>
                          <button
                            type="button"
                            className="btn btn-light-primary w-100"
                            onClick={ () => {
                              setSubscriptionAmount( 1000 );
                              setSubscriptionPlan( 'Platinum' )
                            }}
                          >
                            <b>Platinum</b> <br/>Unlimited Profiles
                          </button>
                        </div>

                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2 pt-5">
                          <span className="required fw-bolder">Subscription Amount (USD)</span>

                          <input
                            type="text"
                            className="form-control form-control-solid"
                            placeholder="Subscription Amount (USD)"
                            name="subscriptionAmount"
                            value={ subscriptionAmount }
                            // onChange={ ( e ) => setSubscriptionAmount( e.target.value ) }
                            // hidden={true}
                            readOnly={ true }
                          />

                        </label>

                        <div
                          className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                      </div>

                      {/* Payment Gateway Dropdown */ }
                      <div className="fv-row mb-8 fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                          <span className="required fw-bolder">Payment Gateway</span>

                        </label>

                        <select
                          className="form-select form-select-solid"
                          data-control="select2"
                          data-hide-search="true"
                          name="paymentGateway"
                          data-placeholder="Select a Payment Gateway"
                          value={ paymentGateway }
                          onChange={ ( e ) => setPaymentGateway( e.target.value ) }
                        >
                          {/*<option value="PayPal">PayPal</option>*/}
                          <option value="PayStack">PayStack</option>
                          {/*<option value="FlutterWave">FlutterWave</option>*/}
                        </select>
                        <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                      </div>

                      {/* Notice */ }
                      <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6 justify-content-center text-center">
                        {/*<div className="d-flex flex-stack flex-grow-1">*/}
                        <div className="fw-semibold">
                          <h6 className="text-gray-900 fw-bold">Redirection</h6>
                          <div className="fs-6 text-gray-700 ">
                            <a
                              href=""
                              className="text-bolder"
                            >
                              You will be redirected to your selected payment gateway.
                            </a>
                          </div>
                        </div>
                        {/*</div>*/}
                      </div>

                      {/* Actions */ }
                      <div className="text-center">
                        <button type="reset" className="btn btn-light-danger me-3" onClick={ handleCancel }>
                          Cancel
                        </button>

                        <button
                          className="btn btn-light-primary"
                          // onClick={handleMakePayment}
                          onClick={openPaystackModal}
                        >
                          <span className="indicator-label">Continue to payment</span>
                          <span className="indicator-progress">
                              Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                      </div>
                    </form>

                  </Modal.Body>

                </Modal>


                <Modal
                  className=""
                  show={ show }
                  onHide={ handleClose }
                  // centered
                  size="sm"
                  backdrop="static"
                  keyboard={ false }
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Add New Profile</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>


                    <form id="ajaxForm" name="ajaxForm">
                      <input type="hidden" name="id_of_table" id="id_of_table"/>
                      <div className="mb-5">
                        <label className="form-label fw-bold">Name:</label>
                        <div>
                          <input
                            id="name"
                            required=""
                            className="flex-grow-1 form-control form-control-solid"
                            name="name"
                            placeholder="Enter your name"
                            type="text"
                            value={ newProfileFormData.name }
                            onChange={ ( e ) => setNewProfileFormData( { ...newProfileFormData, name: e.target.value } ) }
                          />
                        </div>
                      </div>

                      <div className="mb-5">
                        <label className="form-label fw-bold">Email:</label>
                        <div>
                          <input
                            id="email"
                            required=""
                            className="flex-grow-1 form-control form-control-solid"
                            name="email"
                            placeholder="Enter your email"
                            type="email"
                            value={ newProfileFormData.email }
                            onChange={ ( e ) => setNewProfileFormData( { ...newProfileFormData, email: e.target.value } ) }
                          />
                        </div>
                      </div>

                      <div className="mb-5">

                        <label className="form-label fw-bold">Confirm Email:</label>
                        <div>
                          {
                            (confirmEmail !== newProfileFormData.email)
                              ?
                              <><small style={{color: 'red'}}>Email do not match!</small><bt/></>
                              :
                              <></>

                          }
                          <input
                            id="confirmEmail"
                            required=""
                            className="flex-grow-1 form-control form-control-solid"
                            name="confirmEmail"
                            placeholder="Confirm your email"
                            type="email"
                            value={ confirmEmail }
                            onChange={ ( e ) => setConfirmEmail(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="mb-5">
                        <label className="form-label fw-bold">Phone:</label>
                        <div>
                          <input
                            id="phone"
                            required=""
                            className="flex-grow-1 form-control form-control-solid"
                            name="phone"
                            placeholder="Enter your phone number"
                            type="tel"
                            value={ newProfileFormData.phone }
                            onChange={ ( e ) => setNewProfileFormData( { ...newProfileFormData, phone: e.target.value } ) }
                          />
                        </div>
                      </div>
                    </form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button className="btn btn-sm btn-danger" variant="secondary" onClick={ handleClose }>
                      Close
                    </Button>

                    <Button
                      className="btn btn-sm btn-primary me-2 indication me-3"
                      variant="primary"
                      onClick={handleSubmitNewProfile}
                      ref={submitSingleButtonRef}
                    >
                      <span className="indicator-label">Submit</span>
                      <span className="indicator-progress">
                            Please wait...{" "}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"/>
                          </span>


                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  className=""
                  show={showBulkUpload}
                  onHide={handleCloseBulkModal}
                  // centered
                  size="sm"
                  backdrop="static"
                  keyboard={false }
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Upload Bulk</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <form onSubmit={handleBulkUpload}>
                      <input type="hidden" name="id_of_table" id="id_of_table"/>
                      <div className="mb-5">
                        <label className="form-label fw-bold">Select File:</label>
                        <div>
                          <input
                            type="file"
                            className="flex-grow-1 form-control form-control-solid"
                            onChange={(e) => setFile(e.target.files[0])}
                          />
                        </div>
                      </div>
                      <div className="text-center">
                        <Button className="btn btn-sm btn-danger me-3" variant="secondary"
                                onClick={handleCloseBulkModal}>
                          Close
                        </Button>

                        <Button
                          className="btn btn-sm btn-primary me-2 indication me-3"
                          variant="primary"
                          onClick={handleBulkUpload}
                          ref={submitBulkButtonRef}
                        >
                          <span className="indicator-label">Submit</span>
                          <span className="indicator-progress">
                            Please wait...{" "}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"/>
                          </span>


                        </Button>
                      </div>
                    </form>
                  </Modal.Body>
                </Modal>


                <Footer/>

              </div>

            </div>
          </Container>
      }

    </>
  );

};
export default CorporatePage;
