import React, { useState } from 'react';
import api from '../../utils/api';
import Swal from 'sweetalert2';

const AdminForm = ({handleAdminModalClose, updateTable, token}) => {

  const initialUserFormData = {
    name: '',
    username: '',
    password: '',
    email: '',
    phone: ''
  }

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [admin, setAdmin] = useState(initialUserFormData);

  const handleChange = (e) => {
    setAdmin({
      ...admin,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if any of the fields are empty
    if (!admin.name || !admin.username || !admin.password || !admin.email || !admin.phone) {
      Swal.fire({
        title: "Error",
        text: `All fields are required.`,
        allowOutsideClick: false,
        icon: "error",
        showConfirmButton: true,
        customClass: {
          confirmButton: "btn btn-danger"
        }
      });
      return false;
    }

    try {
      let data = JSON.stringify(admin);

      setIsSubmitting(true);

      await api.post(`/admins`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        withCredentials: true,
      });

      updateTable();
      handleAdminModalClose();
      setAdmin(initialUserFormData);

      Swal.fire({
        title: "User added successfully!",
        text: "Success.",
        allowOutsideClick: false,
        icon: "success",
        showConfirmButton: true,
        customClass: {
          confirmButton: "btn btn-info"
        }
      });

    } catch (e) {
      Swal.fire({
        title: "Error",
        text: e.response.data.message,
        allowOutsideClick: false,
        icon: "error",
        showConfirmButton: true,
        customClass: {
          confirmButton: "btn btn-danger"
        }
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" className="form-control form-control-sm" id="name" name="name" value={admin.name} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input type="text" className="form-control form-control-sm" id="username" name="username" value={admin.username} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input type="password" className="form-control form-control-sm" id="password" name="password" value={admin.password} onChange={handleChange} />
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" className="form-control form-control-sm" id="email" name="email" value={admin.email} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <input type="tel" className="form-control form-control-sm" id="phone" name="phone" value={admin.phone} onChange={handleChange} />
          </div>
        </div>
      </div>

      <div className="text-center mt-10">

        <button type="reset" className="btn btn-light-danger me-3  btn-sm" onClick={handleAdminModalClose}>
          Cancel
        </button>

        <button
          className="btn btn-light-primary btn-sm"
        >
          {
            !isSubmitting
              ?
              <span className="indicator-label">Submit</span>
              :
              <span className="indicator-progress">
                Please wait...{ " " }
                <span className="spinner-border spinner-border-sm align-middle ms-2"/>
              </span>
          }
        </button>
      </div>
    </form>
  );
};

export default AdminForm;
