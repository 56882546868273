import React from 'react';

const PageLoader = () => {

  return (

    <>
      {/*begin::loader*/ }
      <div className="d-flex align-items-center justify-content-center container-fluid" style={ { height: '100vh', textAlign: 'center' } }>
        <div id={ `spinner` } className="card-body text-muted align-items-center fs-4">
          <span className="spinner-border text-primary mx-3" role="status" aria-hidden="true"></span>
          Loading...
        </div>
      </div>


      {/*end::Loader*/ }
    </>

  )

}

export default PageLoader;
