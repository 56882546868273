import React, { useEffect, useState } from "react";
import { Modal, Button, Container } from 'react-bootstrap';
import moment from 'moment';
import Swal from 'sweetalert2';
import { Navigate } from 'react-router-dom';

import api from '../utils/api';
import { Header } from '../components/AdminPage/Header';
import PageLoader from '../components/AdminPage/PageLoader';
import { useAuth } from '../context/AuthContext';
import { Footer } from '../components/AdminPage/Footer';
import AdminTables from '../components/AdminPage/AdminTables';
import NewAdminForm from '../components/AdminPage/NewAdminForm';
import TablePagination from '../components/AdminPage/TablePagination';

const AdminPage = () => {

  const { token, isLoading, setIsLoading, pageIsLoading, setPageIsLoading, userType } = useAuth();
  const [adminData, setAdminData] = useState(JSON.parse(localStorage.getItem('user'))); // Add this line
  const [organizations, setOrganizations] = useState([]);
  const [individuals, setIndividuals] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [settings, setSettings] = useState([]);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [clientsCurrentPage, setClientsCurrentPage] = useState(1);
  const [clientsLastPage, setClientsLastPage] = useState(1);
  const [organizationsCurrentPage, setOrganizationsCurrentPage] = useState(1);
  const [organizationsLastPage, setOrganizationsLastPage] = useState(1);
  const [adminsCurrentPage, setAdminsCurrentPage] = useState(1);
  const [adminsLastPage, setAdminsLastPage] = useState(1);
  const [activeTable, setActiveTable] = useState('Clients');

  useEffect(() => {
    setPageIsLoading(false);
  }, []);

  useEffect(() => {
    getClients(clientsCurrentPage).then();
  }, [clientsCurrentPage]);

  const onPageChange = (pageNumber, table) => {
    switch (table) {
      case 'Clients':
        setIsLoading(true);
        setClientsCurrentPage(pageNumber);
        getClients( pageNumber ).then();
        break;
      case 'Organizations':
        setIsLoading(true);
        setOrganizationsCurrentPage(pageNumber);
        getOrganizations(pageNumber).then();
        break;
      case 'Admins':
        setIsLoading(true);
        setAdminsCurrentPage(pageNumber);
        getAdmins(pageNumber).then();
        break;
      default:
        break;
    }
  };

  const handleAdminModalClose = () => setShowAdminModal(false);

  const handleAdminModalShow = () => setShowAdminModal(true);

  const getOrganizations = async (pageNumber = 0) => {
    try {
      const response = await api.get(`/organizations?page=${pageNumber}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        withCredentials: true,
      });
      setOrganizations(response.data);
      setOrganizationsLastPage(response.data.last_page); // Update lastPage state
      setPageIsLoading(false);
    } catch (error) {
      console.error("An error occurred while fetching the organizations:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getClients = async (pageNumber = 0) => {
    try {
      const response = await api.get(`/clients?page=${pageNumber}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        withCredentials: true,
      });
      setIndividuals(response.data);
      setClientsLastPage(response.data.last_page); // Set it to the last_page value from your API response
    } catch (error) {
      console.error("An error occurred while fetching the individuals:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAdmins = async (pageNumber = 0) => {
    try {
      const response = await api.get(`/admins?page=${pageNumber}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        withCredentials: true,
      });

      setAdmins(response.data);
      setAdminsLastPage(response.data.last_page);
    } catch (error) {
      console.error("An error occurred while fetching the users:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getSettings = async () => {
    try {
      // const response = await api.get('/admins', {
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'Authorization': `Bearer ${token}`
      //   },
      //   withCredentials: true,
      // });
      // setSettings(response.data);
    } catch (error) {
      console.error("An error occurred while fetching the settings:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateAdminTable = () => {
    setActiveTable('Admins');
    setIsLoading(true);
    getAdmins().then();
  };

  if ( !token && userType !== 'Admin' ) return <Navigate to={`/admin-login`} />;

  let countClients = 0;
  let countOrganizations = 0;
  let countAdmins = 0;


  return (
    <>
      {
        pageIsLoading
          ?
          <PageLoader />
          :
          <Container fluid>
            <div className="app-main flex-column flex-row-fluid" id="kt_app_main">


              <div className="d-flex flex-column flex-column-fluid">

                <Header clientType={ `Admin` }/>


                {/* Begin Content */}
                <div id="" className="app-content flex-column-fluid">

                  <div className="container">
                    <div className="row h-100">
                      {/* Begin Sidebar */ }
                      <div className="col-lg-3 d-flex flex-column h-100">

                        {/* Begin Card */ }
                        <div className="card shadow-sm">
                          {/* Card header */ }
                          <div className="card-header">
                            {/* Card title */ }
                            <div className="card-title">
                              <h5 className={ `fw-bold` }>Account Details</h5>
                            </div>
                          </div>
                          {/* Card header */ }

                          <div className="card-body pt-0 fs-6">
                            {/* Section */ }
                            <div className="mb-7">
                              {/* Details */ }
                              <div className="d-flex align-items-center">
                                <div className="d-flex flex-column">
                                  {/* Name */ }
                                  <span
                                     className="fs-4 fw-bold text-gray-900 text-hover-primary me-2"
                                  >
                                    { adminData.name }
                                  </span>
                                  {/* Email */ }
                                  <span
                                     className="fw-semibold text-gray-600 text-hover-primary"
                                  >
                                    Email: <b>{ adminData.email }</b>
                                  </span>
                                  <span
                                     className="fw-semibold text-gray-600 text-hover-primary"
                                  >
                                    Phone: <b>{ adminData.phone }</b>
                                  </span>
                                  <span
                                     className="fw-semibold text-gray-600 text-hover-primary"
                                  >
                                    Contact Person: <b>{ adminData.contact_person }</b>
                                  </span>

                                  <Button
                                    className="btn btn-sm btn-light-primary me-2 fw-bold indication mt-5"
                                    variant="primary"
                                    onClick={ () => {
                                      setActiveTable('Clients');
                                      setIsLoading(true);
                                      setClientsCurrentPage(1)
                                      getClients().then();
                                    }}
                                  >
                                    <span className="indicator-label">Clients</span>

                                  </Button>
                                  <Button
                                    className="btn btn-sm btn-light-primary me-2 fw-bold indication mt-5"
                                    variant="primary"
                                    onClick={ () => {
                                      setActiveTable('Organizations');
                                      setIsLoading(true);
                                      setOrganizationsCurrentPage(1)
                                      getOrganizations().then();
                                    }}
                                  >
                                    <span className="indicator-label">Organizations</span>

                                  </Button>
                                  <Button
                                    className="btn btn-sm btn-light-primary me-2 fw-bold indication mt-5"
                                    variant="primary"
                                    onClick={ () => {
                                      setActiveTable('Admins');
                                      setIsLoading(true);
                                      setAdminsCurrentPage(1)
                                      getAdmins().then();
                                    }}
                                  >
                                    <span className="indicator-label">Users</span>

                                  </Button>
                                  {/*<Button*/}
                                  {/*  className="btn btn-sm btn-light-primary me-2 fw-bold indication mt-5"*/}
                                  {/*  variant="primary"*/}
                                  {/*  onClick={ () => {*/}
                                  {/*    setActiveTable('Settings');*/}
                                  {/*    setIsLoading(true);*/}
                                  {/*    getSettings().then();*/}
                                  {/*  }}*/}
                                  {/*>*/}
                                  {/*  <span className="indicator-label">Settings</span>*/}

                                  {/*</Button>*/}
                                </div>
                                {/* Info */ }

                              </div>
                              {/* Details */ }
                            </div>
                            {/* Section */ }

                            {/* Separator */ }
                            <div className="separator separator-dashed mb-7"></div>
                            {/* Separator */ }

                          </div>
                        </div>
                        {/* End Card */ }

                      </div>
                      {/* End Sidebar */ }

                      {/* Begin Table */ }
                      <div className="col-lg-9 d-flex flex-column h-100">

                        {activeTable === 'Clients' && (
                          <>
                          <AdminTables title={ `Clients` } showButton={ false } buttonAction={ () => {} } buttonText={ `Add New` }>
                            <table id="" className="table align-middle table-row-dashed fs-6 fw-bold gy-2">
                              <thead className="border-bottom border-gray-200 fs-7 text-uppercase fw-bold">
                              <tr className="text-start text-muted gs-0">
                                <th className="min-w-50px">S/N</th>
                                <th className="min-w-200px">Name</th>
                                <th className="min-w-100px">Email</th>
                                <th className="min-w-100px">Phone</th>
                              </tr>
                              </thead>
                              <tbody className="fs-5 fw-semibold text-gray-600">
                              { !isLoading && individuals.length !== 0
                                ?
                                (individuals.data.map( ( clients ) => {

                                  // setCountDocuments(countProfiles++);
                                  countClients++;

                                  return <tr key={ clients.client_id }>
                                    <td>
                                      <a href="#" className="text-gray-600 text-hover-primary">{ countClients }</a>
                                    </td>
                                    <td className="text-muted">{ clients.name }</td>
                                    <td className="text-muted">{ clients.email }</td>
                                    <td className="text-muted">{ clients.phone }</td>
                                  </tr>;
                                } ))
                                :
                                isLoading || firstLoad
                                  ? <tr>
                                    <td colSpan={ 5 } className={ `text-center` }>
                                      <div
                                        className="card-body d-flex align-items-center justify-content-center text-muted">
                                    <span className="spinner-border text-primary mx-3" role="status"
                                          aria-hidden="true"></span>
                                        Loading...
                                      </div>
                                    </td>
                                  </tr>
                                  : <tr>
                                    <td colSpan={ 5 } className={ `text-center` }>
                                      <span className="text-gray-600 text-hover-primary">No Clients Found!</span>
                                    </td>
                                  </tr>
                              }
                              </tbody>
                              <tfoot>
                              <tr>
                                <td colSpan={4} className="text-gray-600 text-hover-primary">
                                  <TablePagination currentPage={clientsCurrentPage} lastPage={clientsLastPage} onPageChange={(pageNumber) => onPageChange(pageNumber, 'Clients')} />
                                </td>
                              </tr>
                              </tfoot>
                            </table>
                          </AdminTables>


                          </>
                        ) }

                        { activeTable === 'Organizations' && (
                          <AdminTables title={ `Organizations` } showButton={ false } buttonAction={ () => {} } buttonText={ `Add New` }>
                            <table id="" className="table align-middle table-row-dashed fs-6 fw-bold gy-2">
                              <thead className="border-bottom border-gray-200 fs-7 text-uppercase fw-bold">
                              <tr className="text-start text-muted gs-0">
                                <th className="min-w-50px">S/N</th>
                                <th className="min-w-200px">Organization Name</th>
                                <th className="min-w-100px">Email</th>
                                <th className="min-w-100px">Phone</th>
                              </tr>
                              </thead>
                              <tbody className="fs-5 fw-semibold text-gray-600">
                              { !isLoading && organizations.length !== 0
                                ?
                                (organizations.data.map( ( organization ) => {

                                  countOrganizations++;

                                  return <tr key={ organization.organization_id }>
                                    <td>
                                      <a href="#"
                                         className="text-gray-600 text-hover-primary">{ countOrganizations }</a>
                                    </td>
                                    <td className="text-muted">{ organization.name }</td>
                                    <td className="text-muted">{ organization.email }</td>
                                    <td className="text-muted">{ organization.phone }</td>
                                  </tr>;
                                } ))
                                :
                                isLoading
                                  ? <tr>
                                    <td colSpan={ 5 } className={ `text-center` }>
                                      <div
                                        className="card-body d-flex align-items-center justify-content-center text-muted">
                                    <span className="spinner-border text-primary mx-3" role="status"
                                          aria-hidden="true"></span>
                                        Loading...
                                      </div>
                                    </td>
                                  </tr>
                                  : <tr>
                                    <td colSpan={ 5 } className={ `text-center` }>
                                      <span className="text-gray-600 text-hover-primary">No Organization Found!</span>
                                    </td>
                                  </tr>
                              }
                              </tbody>
                              <tfoot>
                              <tr>
                                <td colSpan={ 4 } className="text-gray-600 text-hover-primary">
                                  <TablePagination currentPage={ organizationsCurrentPage } lastPage={ organizationsLastPage } onPageChange={ ( pageNumber ) => onPageChange( pageNumber, 'Organizations' ) }/>
                                </td>
                              </tr>
                              </tfoot>
                            </table>
                          </AdminTables>
                        ) }
                        { activeTable === 'Admins' && (
                          <AdminTables title={ `Admins` } showButton={ true } buttonAction={ handleAdminModalShow } buttonText={ `Add New` }>
                            <table id="" className="table align-middle table-row-dashed fs-6 fw-bold gy-2">
                              <thead className="border-bottom border-gray-200 fs-7 text-uppercase fw-bold">
                              <tr className="text-start text-muted gs-0">
                                <th className="min-w-50px">S/N</th>
                                <th className="min-w-200px">Client Name</th>
                                <th className="min-w-100px">Email</th>
                                <th className="min-w-100px">Phone</th>
                              </tr>
                              </thead>
                              <tbody className="fs-5 fw-semibold text-gray-600">
                              { !isLoading && admins.length !== 0
                                ?
                                (admins.data.map( ( admin ) => {

                                  countAdmins++;

                                  return <tr key={ admin.admin_id }>
                                    <td>
                                      <a href="#" className="text-gray-600 text-hover-primary">{ countAdmins }</a>
                                    </td>
                                    <td className="text-muted">{ admin.name }</td>
                                    <td className="text-muted">{ admin.email }</td>
                                    <td className="text-muted">{ admin.phone }</td>
                                  </tr>;
                                } ))
                                :
                                isLoading
                                  ? <tr>
                                    <td colSpan={ 5 } className={ `text-center` }>
                                      <div
                                        className="card-body d-flex align-items-center justify-content-center text-muted">
                                    <span className="spinner-border text-primary mx-3" role="status"
                                          aria-hidden="true"></span>
                                        Loading...
                                      </div>
                                    </td>
                                  </tr>
                                  : <tr>
                                    <td colSpan={ 5 } className={ `text-center` }>
                                      <span className="text-gray-600 text-hover-primary">No Admin Found!</span>
                                    </td>
                                  </tr>
                              }
                              </tbody>
                              <tfoot>
                              <tr>
                                <td colSpan={ 4 } className="text-gray-600 text-hover-primary">
                                  <TablePagination currentPage={ adminsCurrentPage } lastPage={ adminsLastPage } onPageChange={ ( pageNumber ) => onPageChange( pageNumber, 'Admins' ) }/>
                                </td>
                              </tr>
                              </tfoot>
                            </table>
                          </AdminTables>
                        ) }
                        { activeTable === 'Settings' && (
                          <AdminTables title={ `Settings` } showButton={ false } buttonAction={ () => {} }
                                       buttonText={ `Add New` }>
                            <h3>Settings Page</h3>
                          </AdminTables>
                        ) }


                        {/*// Inside your AdminPage component*/}
                        {/*<AdminTables title={`Admin Page`} showButton={true} buttonAction={() => {}} buttonText={`Add New`}>*/}
                        {/*  /!* Your table code here *!/*/}

                          {/*<ReactPaginate*/}
                          {/*  previousLabel={'previous'}*/}
                          {/*  nextLabel={'next'}*/}
                          {/*  breakLabel={'...'}*/}
                          {/*  breakClassName={'break-me'}*/}
                          {/*  // pageCount={data.last_page} // total number of pages*/}
                          {/*  pageCount={10}*/}
                          {/*  marginPagesDisplayed={2}*/}
                          {/*  pageRangeDisplayed={5}*/}
                          {/*  onPageChange={handlePageClick} // function to handle page click*/}
                          {/*  containerClassName={'pagination'}*/}
                          {/*  subContainerClassName={'pages pagination'}*/}
                          {/*  activeClassName={'active'}*/}
                          {/*/>*/}
                        {/*</AdminTables>*/}

                      </div>
                      {/* End Table */ }



                      {/* Begin Modal */ }
                      <Modal show={ showAdminModal } onHide={ handleAdminModalClose }>
                        <Modal.Header closeButton>
                          <Modal.Title>Add User</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <NewAdminForm handleAdminModalClose={handleAdminModalClose} updateTable={updateAdminTable} token={token}/>

                        </Modal.Body>
                      </Modal>
                      {/* End Modal */ }
                    </div>
                  </div>

                </div>
                {/* End Content */ }

                <Footer/>

              </div>

            </div>
          </Container>
      }

    </>
  );

};
export default AdminPage;
