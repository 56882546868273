import PaystackPop from '@paystack/inline-js';
import moment from 'moment/moment';
import api from './api';

export const openPaystack = async (data) => {
  const paystack = new PaystackPop();

  let successResponse = 'failed';

  await paystack.newTransaction({
    key: 'pk_test_46f30d9bbce30b16d621c531e7badec32b6b55d7',
    email: data.email,
    amount: 200000, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    metadata: {
      name: data.name,
      phone: data.phone,
      subscriber_id: data.id,
      subscriber_type: data.userType,
      package_name: data.packageType,
      start_date: moment().format( "YYYY-MM-DD" ),
      end_date: moment().add(30, 'days').format('YYYY-MM-DD'),
    },
    onSuccess: (transaction) => {
      successResponse = transaction.reference;
      // verifyTransaction(transaction.reference)
      // Payment complete! Reference: transaction.reference
    },
    onCancel: () => {
      // user closed popup

    }

  });

  return successResponse;
}

export const verifyTransaction = async (transactionReference) => {
  try {
    const verificationStatus =  await api.get( `/paystack/transaction/verify/${ transactionReference }` );
    // const verificationStatus = await api.get(`/paystack/transaction/verify/${ transactionReference }`, {
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Authorization': `Bearer ${token}`
    //   },
    //   withCredentials: true,
    // });

    return verificationStatus.data.status;

  } catch ( e ) {
    return 'failed';
  }

}
