import React from 'react';

export const CtaSection = () => {

  return (
    <>
      <div
        className="cta-section"
        data-bg-image="assets/media/site-images/cta-bg.webp"
        style={ { backgroundImage: 'url("assets/media/site-images/cta-bg.webp")' } }
      >
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              {/* CTA Content Start */ }
              <div className="cta-content">
                <h3 className="title">Have any Questions? <span className="text-bold">Call us now!</span>
                </h3>
                <p>We provide dedicated support 24/7 for any of your questions</p>
              </div>
              {/* CTA Content End */ }
            </div>
            <div className="col-lg-5">
              {/* CTA Phone Number Start */ }
              <div className="cta-phone text-lg-end text-start">
                <h2 className="title"> +1 226-975-4491</h2>
              </div>
              {/* CTA Phone Number End */ }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
