import React, { useEffect, useState } from 'react';
import { Modal, Button, Container } from 'react-bootstrap';
import moment from 'moment';
import Swal from 'sweetalert2';
import PaystackPop from '@paystack/inline-js';
import { useAuth } from '../context/AuthContext';

import api from '../utils/api';
import { Header } from '../components/AdminPage/Header';
import { verifyTransaction } from '../utils/paystackFunction';
import PageLoader from '../components/AdminPage/PageLoader';
import { Footer } from '../components/AdminPage/Footer';
import DocumentTypeOptions from '../components/AdminPage/documentTypeOptions';
import { Navigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import {Bounce, toast} from 'react-toastify';


const IndividualPage = () => {

  const { token, userType, isLoading, setIsLoading, pageIsLoading, setPageIsLoading } = useAuth();

  const [show, setShow] = useState(false);
  const [showPayModal, setShowPayModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // State for form inputs
  const [subscriptionAmount, setSubscriptionAmount] = useState(0);
  const [paymentGateway, setPaymentGateway] = useState('PayStack');
  const [clientData, setClientData] = useState({}); // Add this line

  const clientId = localStorage.getItem('userId');

  const initialDocumentFormData = {
    document_type: '',
    expiry_date: '',
  }

  const [ documentFormData, setDocumentFormData ] = useState( initialDocumentFormData );
  const [dataLoaded, setDataLoaded] = useState(false); // Add this line

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (clientId) {
        await getClientData();
      }
      setIsLoading(false);
      setDataLoaded(true); // Set dataLoaded to true after data is fetched
    };

    if (clientId) {
      fetchData().then();
    } else {
      setPageIsLoading(false);
    }

  }, []);

  const getClientData = async () => {
    setIsLoading(true);

    try {
      const responseData = await api.get(`/clients/${clientId}/details`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        withCredentials: true,
      });

      await setClientData(responseData.data);
    } catch (error) {
      console.error('Login failed', error.message);
    } finally {
      setIsLoading(false);
      setPageIsLoading(false);
    }
  };

  const handleSubmitCreateDocument = async (e) => {
    e.preventDefault();

    try {
      let data = JSON.stringify({ ...documentFormData, client_id: clientData.client_id });

      setIsSubmitting(true);

      // await api.post(`/client-documents`, data);
      await api.post(`/clients/${clientId}/documents`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        withCredentials: true,
      });

      handleClose();
      getClientData();
      setDocumentFormData(initialDocumentFormData);

      Swal.fire({
        title: "Document added successfully!",
        text: "Success.",
        allowOutsideClick: false,
        icon: "success",
        showConfirmButton: true,
        customClass: {
          confirmButton: "btn btn-info"
        }
      });

    } catch (e) {
      Swal.fire({
        title: "Error",
        text: e.response.data.message[0],
        allowOutsideClick: false,
        icon: "error",
        showConfirmButton: true,
        customClass: {
          confirmButton: "btn btn-danger"
        }
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = ( event ) => {
    event.preventDefault();
    // Add logic for form submission
    // console.log('Form submitted:', { subscriptionAmount,paymentGateway });
  };

  const handleCancel = () => {
    setShowPayModal( false );
  };

  const handleClose = () => setShow( false );

  const handleShow = () => {

    if ( clientData.subscriptions.length === 0 ) {
      Swal.fire( {
        title: "No Subscription Found!",
        text: `Select a subscription plan to continue`,
        allowOutsideClick: false,
        // toast: true,
        icon: "error",
        showConfirmButton: true,
        customClass: {
          confirmButton: "btn btn-primary"
        }
        // timer: 2000,
        // timerProgressBar: true,
      } );
    } else {
      setShow( true )
    }

  };

  const verifyTransaction = async (transactionReference) => {
    try {
      // const verificationStatus =  await api.get( `/paystack/transaction/verify/${ transactionReference }` );
      const verificationStatus = await api.get(`/paystack/transaction/verify/${ transactionReference }`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        withCredentials: true,
      });

      return verificationStatus.data.status;

    } catch ( e ) {
      return 'failed';
    }

  }

  const openPaystack = async () => {
    const paystack = new PaystackPop();

    await paystack.newTransaction({
      key: 'pk_test_46f30d9bbce30b16d621c531e7badec32b6b55d7',
      email: clientData.email,
      amount: 200000, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
      metadata: {
        name: clientData.name,
        phone: clientData.phone,
        subscriber_id: clientData.client_id,
        subscriber_type: "Individual",
        package_name: "Individual",
        start_date: moment().format( "YYYY-MM-DD" ),
        end_date: moment().add(30, 'days').format('YYYY-MM-DD'),
      },
      onSuccess: (transaction) => {
        setShowPayModal(false);
        setIsLoading(true);
        setSubscriptionAmount(0);

        setTimeout(async ()=>{
          await verifyTransaction( transaction.reference );

          await getClientData();

          toast.success('Payment Successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }, 8000)
        // Payment complete! Reference: transaction.reference
      },
      onCancel: () => {
        // user closed popup

      }

    });
  }

  const getNumberOfDays = (date) => {
    return (moment().diff( moment( date ).format( "YYYY-MM-DD" ), 'days' ) * -1)
  }

  if ( !token && userType !== 'Individual' ) return <Navigate to={`/individual-login`} />;

  let countDocuments = 0;

  return (

    <>
      {
        pageIsLoading
          ?
          <PageLoader />
          :
          <Container fluid>
            <div className="app-main flex-column flex-row-fluid" id="kt_app_main">


              <div className="d-flex flex-column flex-column-fluid">

                <Header clientType={ `Client` }/>

                {/* Begin Content */}
                <div id="" className="app-content flex-column-fluid">

                  <div className="container">
                    <div className="row h-100">
                      {/* Begin Sidebar */ }
                      <div className="col-lg-4 d-flex flex-column h-100">

                        {/* Begin Card */ }
                        <div className="card shadow-sm">
                          {/* Card header */ }
                          <div className="card-header">
                            {/* Card title */ }
                            <div className="card-title">
                              <h5 className={ `fw-bold` }>Account Details</h5>
                            </div>
                          </div>
                          {/* Card header */ }

                          <div className="card-body pt-0 fs-6">
                            {/* Section */ }
                            <div className="mb-7">
                              {/* Details */ }
                              <div className="d-flex align-items-center">

                                <div className="d-flex flex-column">
                                  {/* Name */ }
                                  <a href="#"
                                     className="fs-4 fw-bold text-gray-900 text-hover-primary me-2"
                                  >
                                    { clientData.name }
                                  </a>
                                  <a href="#"
                                     className="fw-semibold text-gray-600 text-hover-primary"
                                  >
                                    Email: <b>{ clientData.email }</b>
                                  </a>
                                  <a href="#"
                                     className="fw-semibold text-gray-600 text-hover-primary"
                                  >
                                    Phone: <b>{ clientData.phone }</b>
                                  </a>

                                  <Button
                                    className="btn btn-sm btn-light-primary me-2 fw-bold indication mt-5"
                                    variant="primary"
                                    onClick={ getClientData }
                                  >
                                    <span className="indicator-label">Check Subscription Status</span>

                                  </Button>
                                </div>
                                {/* Info */ }

                              </div>
                              {/* Details */ }
                            </div>
                            {/* Section */ }

                            {/* Separator */ }
                            <div className="separator separator-dashed mb-7"></div>
                            {/* Separator */ }

                            {
                              !isLoading && dataLoaded
                                ?
                                <>
                                  {/* Section */ }
                                  <div className="mb-10">

                                    {
                                      clientData.subscriptions.length === 0
                                        ?
                                        <h5 className="mb-4">No Subscription Found!</h5>
                                        :
                                        <>
                                          {/* Title */}
                                          <h5 className="mb-4">Subscription Details</h5>

                                          {/* Details */}
                                          <table
                                            className="table table-row-dashed table-row-gray-300 fs-6 fw-semibold gs-0 gy-2 gx-0">

                                            <tbody>

                                            {/* Row */}
                                            <tr className="">
                                              <td className="text-gray-500">Status:</td>
                                              <td>
                                                {
                                                  clientData.subscriptions.length === 0
                                                  ?
                                                  <span className="badge badge-light-warning">Not Active</span>
                                                  :
                                                  moment().isAfter(moment(clientData.subscriptions[0].end_date, 'YYYY-MM-DD'))
                                                  ?
                                                  <span className="badge badge-light-danger">Expired</span>
                                                  :
                                                  <span className="badge badge-light-success">Active</span>
                                                }

                                              </td>
                                            </tr>
                                            {/* Row */}
                                            <tr className="">
                                              <td className="text-gray-500">Package:</td>
                                              <td className="text-gray-800">
                                                {
                                                  clientData.subscriptions[0].subscription_by === null
                                                    ?
                                                    clientData.subscriptions[0].package_name
                                                    :
                                                    clientData.subscriptions[0].subscription_by.name
                                                }
                                              </td>
                                            </tr>
                                            {/* Row */}
                                            {
                                              clientData.subscriptions[0].subscription_by === null
                                                &&
                                                <tr className="">
                                                  <td className="text-gray-500">Started:</td>
                                                  <td className="text-gray-800">
                                                    {moment(clientData.subscriptions[0].start_date).format("ddd, MMM Do YYYY")}
                                                  </td>
                                                </tr>
                                            }

                                            {/* Row */}
                                            <tr className="">
                                              <td className="text-gray-500">Expires:</td>
                                              <td
                                                className="text-gray-800">{moment(clientData.subscriptions[0].end_date).format("ddd, MMM Do YYYY")}</td>
                                            </tr>
                                            {/* Row */}
                                            {/*{*/}
                                            {/*  clientData.subscriptions[0].subscription_by === null*/}
                                            {/*  &&*/}
                                            {/*  <tr className="">*/}
                                            {/*    <td className="text-gray-500">Next Renewal:</td>*/}
                                            {/*    <td*/}
                                            {/*      className="text-gray-800">{moment(clientData.subscriptions[0].end_date).add(1, 'day').format("ddd, MMM Do YYYY")}</td>*/}
                                            {/*  </tr>*/}
                                            {/*}*/}

                                            {/* Row */}
                                            {/*<tr className="">*/}
                                            {/*  <td className="text-gray-500">Remaining Credit:</td>*/}
                                            {/*  <td className="text-gray-800 fw-bolder fs-5">{ 50 }</td>*/}
                                            {/*</tr>*/}


                                            </tbody>
                                          </table>
                                          {/* Details */}

                                        </>

                                    }

                                    {
                                      clientData.subscriptions.length === 0 || moment().isAfter( moment( clientData.subscriptions[0].end_date, 'YYYY-MM-DD' ) )
                                        ?
                                        <div className="mb-0">
                                          <button
                                            className="btn btn-flex btn-light-primary fw-bold"
                                            onClick={ () => openPaystack() }
                                          >
                                            Add Subscription
                                          </button>
                                        </div>
                                        :
                                        <div className="mb-0">
                                          <button
                                            className="btn btn-success fw-bold btn-flex btn-light-success"
                                          >
                                            Subscription Active
                                          </button>
                                        </div>
                                    }


                                  </div>
                                  {/* Section */ }

                                  {/* Actions */ }
                                  {/*<div className="mb-0">*/ }
                                  {/*  <a*/ }
                                  {/*    href="#" className="btn btn-primary"*/ }
                                  {/*    id="kt_subscriptions_create_button"*/ }
                                  {/*    onClick={ () => setShowPayModal( true ) }*/ }
                                  {/*  >*/ }
                                  {/*    Add Subscription*/ }
                                  {/*  </a>*/ }
                                  {/*</div>*/ }
                                  {/* Actions */ }
                                </>
                                :

                                <div className="card-body d-flex align-items-center justify-content-center text-muted">
                                  <span className="spinner-border text-primary mx-3" role="status" aria-hidden="true"></span>
                                  Loading...
                                </div>

                            }

                          </div>
                        </div>
                        {/* End Card */ }

                      </div>
                      {/* End Sidebar */ }

                      {/* Begin Table */ }
                      <div className="col-lg-8 d-flex flex-column h-100">

                        {/* Begin Card */ }
                        <div className="card shadow-sm">

                          {/* Card Header */ }
                          <div className="card-header border-0">
                            {/* Card Title */ }
                            <div className="card-title">
                              <h5 className={ `fw-bold` }>My Documents</h5>
                            </div>
                            {/* End Card Title */ }
                            {/* Toolbar */ }
                            <div className="card-toolbar m-0">
                              {
                                !isLoading && dataLoaded && clientData.subscriptions.length !== 0 &&
                                !moment().isAfter( moment( clientData?.subscriptions[0].end_date, 'YYYY-MM-DD' )) &&
                                <a href="#" className="btn btn-sm btn-flex btn-light-primary fw-bold" onClick={handleShow}>
                                  <i className="ki-duotone ki-plus-square fs-3">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                  </i> Add New Document
                                </a>
                              }


                            </div>
                            {/* End Toolbar */}
                          </div>
                          {/* End Card Header */}

                          {/* Card Body */}
                          <div className="card-body pt-0">

                            <div className={`table-responsive`}>
                              {/* Begin Table */}
                              <table id="" className="table align-middle table-row-dashed fs-6 fw-bold gy-2">
                              {/* Table Header */ }
                                <thead className="border-bottom border-gray-200 fs-7 text-uppercase fw-bold">
                                <tr className="text-start text-muted gs-0">
                                  <th className="min-w-50px">S/N</th>
                                  <th className="min-w-200px">Document Type</th>
                                  <th className="min-w-100px">Status</th>
                                  <th className="min-w-100px">Expiry Date</th>
                                  {/*<th className="min-w-100px text-end pe-7">Update</th>*/ }
                                </tr>
                                </thead>
                                {/* Table Body */ }
                                <tbody className="fs-5 fw-semibold text-gray-600">


                                {
                                  !isLoading && dataLoaded && clientData.documents.length !== 0
                                    ?
                                    (clientData.documents.map( ( clientDocument ) => {

                                      // setCountDocuments(countDocuments++);
                                      countDocuments++;

                                      return <tr key={ clientDocument.client_document_id }>
                                        <td>
                                          <a href="#" className="text-gray-600 text-hover-primary">{ countDocuments }</a>
                                        </td>
                                        <td className="text-muted">{ clientDocument.document_type }</td>
                                        <td>
                                          {
                                            (getNumberOfDays(clientDocument.expiry_date ) < 0)
                                              ?
                                              <span className="badge badge-light-danger fs-6 fw-bold">Document Expired</span>

                                              :
                                              (getNumberOfDays(clientDocument.expiry_date ) > 0 && getNumberOfDays(clientDocument.expiry_date ) <= 60)
                                                ?
                                                <span
                                                  className="badge badge-light-info fs-6 fw-bold">Expiring Soon</span>
                                                :
                                                <span className="badge badge-light-success fs-6 fw-bold">Document Valid</span>
                                          }
                                          {/*<span className="badge badge-light-success fs-7 fw-bold">OK</span>*/}
                                          {/*<span className="badge badge-light-info">In progress</span>*/}
                                        </td>
                                        <td>{ moment( clientDocument.expiry_date ).format( "ddd, MMM Do YYYY" ) }</td>
                                        {/*<td className="text-end">*/ }
                                        {/*  <button className="btn btn-sm btn-light btn-active-light-success">Renew</button>*/ }
                                        {/*</td>*/ }
                                      </tr>;
                                    } ))
                                    :

                                    isLoading && dataLoaded
                                      ?
                                      <tr>
                                        <td colSpan={ 5 } className={ `text-center` }>
                                          <div
                                            className="card-body d-flex align-items-center justify-content-center text-muted">
                                        <span className="spinner-border text-primary mx-3" role="status"
                                              aria-hidden="true"></span>
                                            Loading...
                                          </div>
                                        </td>
                                      </tr>
                                      :
                                      <tr>
                                        <td colSpan={ 5 } className={ `text-center` }>
                                          <span className="text-gray-600 text-hover-primary">No uploaded document</span>
                                        </td>
                                      </tr>
                                }
                                </tbody>
                              </table>
                              {/* End Table */ }
                            </div>


                          </div>
                          {/* End Card Body */ }

                        </div>
                        {/* End Card */ }

                      </div>
                      {/* End Table */ }
                    </div>
                  </div>

                </div>
                {/* End Content */ }


                <Modal
                  className=""
                  show={ showPayModal }
                  onHide={ handleClose }
                  // centered
                  size="md"
                  backdrop='static'
                  keyboard={ false }
                >
                  <Modal.Body>

                    <form id="kt_modal_bidding_form" className="form fv-plugins-bootstrap5 fv-plugins-framework" action="#"
                          onSubmit={ handleSubmit }>
                      {/* Heading */ }
                      <div className="mb-13 text-center">
                        <h4 className="mb-3 fw-bold">Select Plan</h4>
                      </div>

                      {/* Subscription Amount Input */ }
                      <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
                        {/*<label className="d-flex align-items-center fs-6 fw-semibold mb-2">*/ }
                        {/*  <span className="required">Select Plan</span>*/ }
                        {/*</label>*/ }

                        <div className="d-flex flex-stack gap-5 mb-3">

                          <button
                            type="button"
                            className="btn btn-light-primary w-100"
                            onClick={ () => setSubscriptionAmount( '$10' ) }
                          >
                            Individual <br/>Subscription
                          </button>
                        </div>

                        <input
                          type="text"
                          className="form-control form-control-solid"
                          placeholder="Subscription Amount"
                          name="subscriptionAmount"
                          value={ subscriptionAmount }
                          onChange={ ( e ) => setSubscriptionAmount( e.target.value ) }
                          // hidden={true}
                          readOnly={ true }
                        />
                        <div
                          className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                      </div>

                      {/* Gateway Dropdown */ }
                      <div className="fv-row mb-8 fv-plugins-icon-container">
                        <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                          <span className="required">Payment Gateway</span>

                        </label>

                        <select
                          className="form-select form-select-solid"
                          data-control="select2"
                          data-hide-search="true"
                          name="currency_type"
                          data-placeholder="Select a Currency Type"
                          defaultValue={ paymentGateway }
                          onChange={ ( e ) => setPaymentGateway( e.target.value ) }
                        >
                          {/*<option value="PayPal">PayPal</option>*/ }
                          <option value="PayStack">PayStack</option>
                          {/*<option value="FlutterWave">FlutterWave</option>*/ }
                        </select>
                        <div
                          className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                      </div>

                      {/* Notice */ }
                      <div
                        className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6 text-center">
                        <div className="d-flex flex-stack flex-grow-1">
                          <div className="fw-semibold">
                            <h5 className="text-gray-900 fw-bold">Redirection</h5>
                            <div className="fs-6 text-gray-700 ">
                              <a
                                href=""
                                className="text-bolder"
                              >
                                You will be redirected to your selected payment gateway.
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Actions */ }
                      <div className="text-center">
                        <button type="reset" className="btn btn-light-danger me-3" onClick={ handleCancel }>
                          Cancel
                        </button>

                        <button
                          type="submit"
                          className="btn btn-light-primary"
                          onClick={ () => {
                            // initializePayment( onSuccess, onClose );
                          } }
                        >
                          <span className="indicator-label">Continue to payment</span>
                          <span className="indicator-progress">
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                        </button>

                        {/*<PaystackHookExample />*/ }

                        {/*<button type="submit" className="btn btn-light-primary" onClick={PaystackHookExample}>*/ }
                        {/*  <span className="indicator-label">Continue to payment</span>*/ }
                        {/*  <span className="indicator-progress">*/ }
                        {/*    Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>*/ }
                        {/*  </span>*/ }
                        {/*</button>*/ }
                      </div>
                    </form>

                  </Modal.Body>

                </Modal>


                <Modal
                  className=""
                  show={ show }
                  onHide={ handleClose }
                  // centered
                  size="sm"
                  backdrop="static"
                  keyboard={ false }
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Add New Document</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>


                    <form onSubmit={ handleSubmitCreateDocument }>
                      <input type="hidden" name="id_of_table" id="id_of_table"/>
                      <div className="mb-5">
                        <label className="form-label fw-bold">Document Type:</label>
                        <div>
                          <select
                            className="form-select form-select-solid"
                            data-kt-select2="true"
                            data-placeholder="Select option"
                            data-allow-clear="true"
                            // defaultValue={ 3 }
                            defaultValue={ documentFormData.document_type }
                            onChange={ ( e ) => setDocumentFormData( {
                              ...documentFormData,
                              document_type: e.target.value
                            } ) }
                          >
                            <DocumentTypeOptions/>
                          </select>
                        </div>
                      </div>
                      <div className="mb-5">
                        <label className="form-label fw-bold">Expiry Date:</label>
                        <div>
                          <input
                            id="expiryDate"
                            // pattern="[A-Z]{1}"
                            required=""
                            className="flex-grow-1 form-control form-control-solid "
                            name="expiryDate"
                            placeholder="Enter a name"
                            type={ `date` }
                            value={ documentFormData.expiry_date }
                            // onChange={handleInputChange}
                            onChange={ ( e ) => setDocumentFormData( {
                              ...documentFormData,
                              expiry_date: e.target.value
                            } ) }
                          />
                        </div>
                      </div>
                      <div className="pt-5 mb-2 text-center">
                        <Button className="btn btn-sm btn-light-danger fw-bold me-2" variant="secondary" onClick={ handleClose }>
                          Close
                        </Button>
                        <Button className="btn btn-sm btn-light-primary fw-bold me-2 indication" variant="primary" type={ `submit` }>
                          {
                            !isSubmitting
                              ?
                              <span className="indicator-label">Submit</span>
                              :
                              <>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                              </>
                          }

                        </Button>
                      </div>
                    </form>
                  </Modal.Body>
                  {/*<Modal.Footer>*/}
                  {/*<Button className="btn btn-sm btn-danger" variant="secondary" onClick={ handleClose }>*/ }
                  {/*  Close*/ }
                  {/*</Button>*/ }
                  {/*<Button className="btn btn-sm btn-primary me-2 indication" variant="primary" type={`submit`}>*/ }
                  {/*  {*/ }
                  {/*    !isSubmitting*/ }
                  {/*      ?*/ }
                  {/*      <span className="indicator-label">Submit</span>*/ }
                  {/*      :*/ }
                  {/*      <>*/ }
                  {/*        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>*/ }
                  {/*        Loading...*/ }
                  {/*      </>*/ }
                  {/*  }*/ }

                  {/*</Button>*/ }
                  {/*<button className="btn btn-primary" type="button" disabled>*/ }
                  {/*  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>*/ }
                  {/*  Loading...*/ }
                  {/*</button>*/ }
                  {/*</Modal.Footer>*/}
                </Modal>

                <Footer />

              </div>

            </div>
          </Container>
      }

    </>

  );

};
export default IndividualPage;
