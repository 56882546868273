import {Link} from 'react-router-dom';
import React from 'react';

export const NotFound = () => {

  const token = localStorage.getItem('token');
  const userType = localStorage.getItem('userType');

  return (

    <>
      <div className="card-px text-center pt-15 pb-15">
        <h2 className="fs-2x fw-bold mb-0">404 - Not Found</h2>
        <p className="text-gray-500 fs-4 fw-semibold py-7">
          The page you are looking for does not exist.</p>

        {token && userType ? <Link to={`/${userType.toLowerCase()}`}>Go to User Page</Link> : <Link to={`/`}>Go Back Home</Link>}
      </div>
    </>
  );
}
