import React from 'react';

export const AboutSection = () => {

  return (
    <>
      <div id={ `about` } className="about-section">
        <div className="auto-container">
          <div className="inner-container">
            <div className="row align-items-center clearfix">
              {/* Image Column */ }
              <div className="image-column col-lg-6">
                <div className="about-image">
                  <div className="about-inner-image">
                    <img src="assets/media/site-images/home-about.webp" alt="about"/>
                  </div>
                </div>
              </div>

              {/* Content Column */ }
              <div className="content-column col-lg-6 col-md-12 col-sm-12 mb-0">
                <div className="about-column">
                  <div className="sec-title">
                    <div className="title">About DocRenew</div>
                    <h2>Leading <span>Immigration</span> <br/> <span>Consulting</span> Company</h2>
                  </div>
                  <div className="" style={ { fontSize: 18 } }>
                    <p>At Damtimore Group, we understand the challenges individuals and businesses and organisations
                      face in keeping track of various document expiration dates. Whether it's licenses,
                      certifications, permits, or any other crucial documents, our system is designed to be your
                      reliable companion in managing and renewing them seamlessly.
                    </p>
                    <p>What sets us apart is our commitment to user-friendly and intuitive solutions. We believe that
                      managing document renewals should be as straightforward as possible. Our system employs
                      cutting-edge technology to provide timely and accurate reminders, allowing you to focus on your
                      core activities without the stress of tracking expiration dates.</p>
                  </div>
                  {/*<div className="signature">Daniel <span>Daniel Jr</span></div>*/ }
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
