import React from 'react';
// import moment from 'moment/moment';

export const Footer = () => {

  return (
    <>
      <footer className="mt-auto bg-light text-center py-4">
        <div className="container">
          <span className="text-muted fw-semibold ">
            {/*<span className={`mx-3`}>{ moment().format( "YYYY" ) }</span>*/}
             Powered by
            <a href="https://www.loftyedge.com" target="_blank" className="text-gray-800 text-hover-primary mx-2">LoftyEdge</a>
          </span>
        </div>
      </footer>
    </>
  );
}
