import React from 'react';

export const SubscriptionPlans = () => {


  return (
    <div id={ `plans` } className="pb-20">

      <div className="d-flex flex-column">
        <div className="text-center">
          {/* Sec Title */ }
          <div className="sec-title">
            <div className="clearfix">
              <div className="">
                <h2><span>Choose A Plan</span></h2>
                {/*<div className="text-gray-700 fw-semibold fs-5">*/ }
                {/*  Corporate Plans.*/ }
                {/*</div>*/ }
              </div>
            </div>
          </div>


        </div>


        <div className="container">
          <div className="row justify-content-center text-center align-items-center gap-4">
            <div className="col-xl-2 col-lg-4 col-md-6">
              <div className="card shadow-sm">
                {/* Option - Individual */ }
                <div
                  className="d-flex flex-column flex-center rounded-3 shadow bg-light bg-opacity-75 py-15 px-8">
                  {/* Heading - Individual */ }
                  <div className="mb-7 text-center">
                    {/* Title - Individual */ }
                    <h3 className="text-gray-900">Individual</h3>
                    {/* Description - Individual */ }
                    <div className="text-gray-700 fw-bold mb-5">
                      ( Individual Plan )
                    </div>
                    {/* Price - Individual */ }
                    <div className="text-center">
                      <span className="mb-2 theme_color">$</span>
                      <span className="fs-3x fw-bold theme_color">
                            10
                        </span>
                      <span className="fs-6 fw-semibold">/
                            <span data-kt-element="period">Month</span>
                        </span>
                    </div>
                  </div>
                  {/* Features - Individual */ }
                  <div className="mb-10">
                    {/* Feature Items */ }
                    {/* Item - Individual */ }
                    <div className="d-flex align-items-center mb-5">
                        <span className="fw-semibold fs-3 text-gray-800 flex-grow-1 pe-3">
                            1 Profile
                        </span>
                      <i className="ki-duotone ki-check-circle fs-1 text-success"><span
                        className="path1"></span><span
                        className="path2"></span></i>
                    </div>
                    {/* Add more feature items as needed */ }
                  </div>
                  {/* Select - Individual */ }
                  <a
                    href={ `#formSection` }
                    className="theme-btn btn-style-three py-5"
                    style={ {
                      padding: '10px',
                      lineHeight: '5px',
                      fontSize: '18px'
                    } }
                  >
                    <span className="txt">Get Started</span>
                  </a>
                </div>
                {/* End Option - Individual */ }
              </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-6">
              <div className="card shadow-sm">
                {/* Option - Bronze */ }
                <div
                  className="d-flex flex-column flex-center rounded-3 shadow bg-light bg-opacity-75 py-15 px-8">
                  {/* Heading - Bronze */ }
                  <div className="mb-7 text-center">
                    {/* Title - Bronze */ }
                    <h3 className="text-gray-900">Bronze</h3>
                    {/* Description - Bronze */ }
                    <div className="text-gray-700 fw-bold mb-5">
                      ( Corporate Plan )
                    </div>
                    {/* Price - Bronze */ }
                    <div className="text-center">
                      <span className="mb-2 theme_color">$</span>
                      <span className="fs-3x fw-bold theme_color">
                            50
                        </span>
                      <span className="fs-6 fw-semibold">/
                            <span data-kt-element="period">Month</span>
                        </span>
                    </div>
                  </div>
                  {/* Features - Individual */ }
                  <div className="mb-10">
                    {/* Feature Items */ }
                    {/* Item - Individual */ }
                    <div className="d-flex align-items-center mb-5">
                        <span className="fw-semibold fs-3 text-gray-800 flex-grow-1 pe-3">
                            50 Profile
                        </span>
                      <i className="ki-duotone ki-check-circle fs-1 text-success"><span
                        className="path1"></span><span
                        className="path2"></span></i>
                    </div>
                    {/* Add more feature items as needed */ }
                  </div>
                  {/* Select - Individual */ }
                  <a
                    href={ `#formSection` }
                    className="theme-btn btn-style-three py-5"
                    style={ {
                      padding: '10px',
                      lineHeight: '5px',
                      fontSize: '18px'
                    } }
                  >
                    <span className="txt">Get Started</span>
                  </a>
                </div>
                {/* End Option - Individual */ }
              </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-6">
              <div className="card shadow-sm">
                {/* Option - Silver */ }
                <div
                  className="d-flex flex-column flex-center rounded-3 shadow bg-light bg-opacity-75 py-15 px-8">
                  {/* Heading - Silver */ }
                  <div className="mb-7 text-center">
                    {/* Title - Silver */ }
                    <h3 className="text-gray-900">Silver</h3>
                    {/* Description - Startup */ }
                    <div className="text-gray-700 fw-bold mb-5">
                      ( Corporate Plan )
                    </div>
                    {/* Price - Silver */ }
                    <div className="text-center">
                      <span className="mb-2 theme_color">$</span>
                      <span className="fs-3x fw-bold theme_color">
                            100
                        </span>
                      <span className="fs-6 fw-semibold">/
                            <span data-kt-element="period">Month</span>
                        </span>
                    </div>
                  </div>
                  {/* Features - Silver */ }
                  <div className=" mb-10">
                    {/* Feature Items */ }
                    {/* Item - Silver */ }
                    <div className="d-flex align-items-center mb-5">
                        <span className="fw-semibold fs-3 text-gray-800 flex-grow-1 pe-3">
                            Up to 100 Profiles
                        </span>
                      <i className="ki-duotone ki-check-circle fs-1 text-success"><span
                        className="path1"></span><span
                        className="path2"></span></i>
                    </div>
                    {/* Add more feature items as needed */ }
                  </div>
                  {/* Select - Silver */ }
                  <a
                    href={ `#formSection` }
                    className="theme-btn btn-style-three py-5"
                    style={ {
                      padding: '10px',
                      lineHeight: '5px',
                      fontSize: '18px'
                    } }
                  >
                    <span className="txt">Get Started</span>
                  </a>
                </div>
                {/* End Option - Silver */ }
              </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-6">
              <div className="card shadow-sm">
                {/* Option - Gold */ }
                <div
                  className="d-flex flex-column flex-center rounded-3 bg-light shadow bg-opacity-75 py-20 px-8">
                  {/* Heading - Gold */ }
                  <div className="mb-7 text-center">
                    {/* Title - Gold */ }
                    <h3 className="text-gray-900">Gold</h3>
                    {/* Description - Gold */ }
                    <div className="text-gray-700 fw-bold mb-5">
                      ( Corporate Plan )
                    </div>
                    {/* Price - Gold */ }
                    <div className="text-center">
                      <span className="mb-2 theme_color">$</span>
                      <span className="fs-3x fw-bold theme_color">
                            150
                        </span>
                      <span className="fs-6 fw-semibold">/
                            <span data-kt-element="period">Month</span>
                        </span>
                    </div>
                  </div>
                  {/* Features - Gold */ }
                  <div className=" mb-10">
                    {/* Feature Items */ }
                    {/* Item - Gold */ }
                    <div className="d-flex align-items-center mb-5">
                        <span className="fw-semibold fs-3 text-gray-800 flex-grow-1 pe-3">
                            Up to 500 Profiles
                        </span>
                      <i className="ki-duotone ki-check-circle fs-1 text-success"><span
                        className="path1"></span><span
                        className="path2"></span></i>
                    </div>
                    {/* Add more feature items as needed */ }
                  </div>
                  {/* Select - Gold */ }
                  <a
                    href={ `#formSection` }
                    className="theme-btn btn-style-three py-5"
                    style={ {
                      padding: '10px',
                      lineHeight: '5px',
                      fontSize: '18px'
                    } }
                  >
                    <span className="txt">Get Started</span>
                  </a>
                </div>
                {/* End Option - Gold */ }
              </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-6">
              <div className="card shadow-sm">
                {/* Option - Platinum */ }
                <div
                  className="d-flex flex-column flex-center rounded-3 bg-light shadow bg-opacity-75 py-15 px-8">
                  {/* Heading - Platinum */ }
                  <div className="mb-7 text-center">
                    {/* Title - Platinum */ }
                    <h3 className="text-gray-900">Platinum</h3>
                    {/* Description - Platinum */ }
                    <div className="text-gray-700 fw-bold mb-5">
                      ( Corporate Plan )
                    </div>
                    {/* Price - Enterprise */ }
                    <div className="text-center">
                      <span className="mb-2 theme_color">$</span>
                      <span className="fs-3x fw-bold theme_color">
                            300
                        </span>
                      <span className="fs-6 fw-semibold">/
                            <span data-kt-element="period">Month</span>
                        </span>
                    </div>
                  </div>
                  {/* Features - Platinum */ }
                  <div className=" mb-10">
                    {/* Feature Items */ }
                    {/* Item - Platinum */ }
                    <div className="d-flex align-items-center mb-5">
                        <span className="fw-semibold fs-3 text-gray-800 flex-grow-1 pe-3">
                            Unlimited Profiles
                        </span>
                      <i className="ki-duotone ki-check-circle fs-1 text-success"><span
                        className="path1"></span><span
                        className="path2"></span></i>
                    </div>
                    {/* Add more feature items as needed */ }
                  </div>
                  {/* Select - Platinum */ }
                  <a
                    href={ `#formSection` }
                    className="theme-btn btn-style-three py-5"
                    style={ {
                      padding: '10px',
                      lineHeight: '5px',
                      fontSize: '18px'
                    } }
                  >
                    <span className="txt">Get Started</span>
                  </a>
                </div>
                {/* End Option - Platinum */ }
              </div>
            </div>
          </div>
        </div>

      </div>


    </div>
  );
}
