import React from 'react';
import { useNavigate} from "react-router-dom";
import { useAuth } from '../../context/AuthContext';

export const Header = (props) => {
  const nav = useNavigate();

  const { logout } = useAuth();

  const handleLogout = async (e) => {

    try{
      logout();

      if ( props.clientType.toLowerCase() === 'client' ) return nav("/individual-login");

      return nav(`/${props.clientType.toLowerCase()}-login`);
    }
    catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <div id="" className="py-3 py-lg-6">
        <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <a href={ `/` }><h1
              className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">DocRenew</h1>
            </a>
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
              <li className="breadcrumb-item text-muted">
                <a href="#" className="text-muted text-hover-primary">{ props.clientType }</a>
              </li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-500 w-5px h-2px"></span>
              </li>
              <li className="breadcrumb-item text-muted">Dashboard</li>
            </ul>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-3">
            <button
              className="btn btn-sm fw-bold btn-flex btn-light-primary"
              onClick={ handleLogout }
            >
              Log Out
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
