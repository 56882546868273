import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const PrivateRoute = ({ element, allowedUserTypes }) => {
  const { token, userType } = useAuth();
  const location = useLocation();

  const currentPath = location.pathname.slice(1);

  if (!token || userType === '') {
    if ( currentPath === 'admin' || currentPath === 'corporate' || currentPath === 'individual' ) {
      return <Navigate to={`/${currentPath}-login`} />;
    }
  }

  // Check if the user type is allowed for the route
  if (allowedUserTypes.includes(userType)) {
    return element;
  } else {
    // Redirect to a default route or display an error message for unauthorized user types
    return <Navigate to={`/${userType.toLowerCase()}`} />;
  }
};

export default PrivateRoute;
