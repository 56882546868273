import React from "react";

import { SubscriptionPlans } from '../components/HomePage/SubscriptionPlans';
import { Footer } from '../components/HomePage/Footer';
import { Contact } from '../components/HomePage/Contact';
import { HomePageMap } from '../components/HomePage/HomePageMap';
import { Faq } from '../components/HomePage/Faq';
import { CtaSection } from '../components/HomePage/CtaSection';
import { AboutSection } from '../components/HomePage/AboutSection';
import { ReputationSection } from '../components/HomePage/ReputationSection';
import { BannerSection } from '../components/HomePage/BannerSection';


const HomePage = () => {


  return (
    <>

      <div className="page-wrapper">

        {/* Banner Section */ }
        <BannerSection />
        {/* End Banner Section */ }

        {/* CTA Section Start */ }
        <CtaSection />
        {/* CTA Section End */ }

        {/* About Section Start */ }
        <AboutSection />
        {/* About Section End */ }

        {/* Plan Section Start */ }
        <SubscriptionPlans />
        {/* Plan Section End */ }

        {/* Reputation Section Two*/ }
        <ReputationSection />
        {/* End Reputation Section*/ }

        {/* FAQ Section */ }
        <Faq />
        {/* FAQ Section End */ }

        {/* Map Section */}
        <HomePageMap />
        {/* End Map Section */}

        {/* Contact Section */}
        <Contact />
        {/* End Contact Section */}

        {/*  Footer Section*/}
        <Footer />
        {/*  Footer Section*/}

      </div>

    </>
  );

};
export default HomePage;
