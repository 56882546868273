import React from 'react';

const TablePagination = ({ currentPage, lastPage, onPageChange }) => {
  const renderPaginationLinks = () => {
    const links = [];
    for (let i = 1; i <= lastPage; i++) {
      links.push(
        <li className={`page-item ${i === currentPage ? 'active' : ''}`} key={i}>
          <button className="page-link" onClick={() => onPageChange(i)}>{i}</button>
        </li>
      );
    }
    return links;
  };

  return (
    <nav aria-label="Page navigation">
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => onPageChange(currentPage - 1)}>&laquo; Previous</button>
        </li>
        {renderPaginationLinks()}
        <li className={`page-item ${currentPage === lastPage ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => onPageChange(currentPage + 1)}>Next &raquo;</button>
        </li>
      </ul>
    </nav>
  );
};

export default TablePagination;
