import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

export const ReputationSection = () => {


  let initialFormState = {
    name: '',
    email: '',
    phone: '',
    address: '',
    contact_person: '',
    subscriptionType: 'Individual', // Default value
  };

  const [formData, setFormData] = useState(initialFormState);
  const [isSubmitting, setIsSubmitting] = useState(false);


  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    try {

      const urlType = (formData.subscriptionType === 'Individual') ? 'clients' : 'organizations';

      let data = JSON.stringify(formData);

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `https://api.docrenew.com/api/v1/authentication/${urlType}/register`,
        headers: {
          'Content-Type': 'application/json'
        },
        data : data
      };

      await axios.request(config);

      setFormData(initialFormState);

      Swal.fire({
        title: "Your account has been created!",
        text: "Check your mail for login details.",
        allowOutsideClick: false,
        // toast: true,
        icon: "success",
        showConfirmButton: true,
        // timer: 2000,
        // timerProgressBar: true,
      });


    } catch ( e ) {

      // console.log(e);

      Swal.fire({
        title: "Error",
        text: e.response.data.message,
        allowOutsideClick: false,
        // toast: true,
        icon: "error",
        showConfirmButton: true,
        // timer: 2000,
        // timerProgressBar: true,
      });
    }
    finally {
      setIsSubmitting(false)
    }



  };

  return (
    <>
      <div id={ `formSection` } className="d-flex justify-content-center reputation-section-two style-two">
        <div className="auto-container">
          <div className="row clearfix">

            {/* Form Column */ }
            <div className="form-column col-lg-5 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="form-boxed">
                  <h5>Subscription Form</h5>

                  <div className="consult-form">
                    <form id={ `registerForm` } onSubmit={ handleSubmit }>

                      {/* Form Group */ }
                      <div className="form-group">
                        <label>Name</label>
                        <input
                          type="text"
                          name="name"
                          value={ formData.name }
                          placeholder="Individual Name or Organization"
                          onChange={ handleInputChange } required/>
                      </div>

                      <div className="form-group">
                        <label>Email</label>
                        <input
                          type="email"
                          name="email"
                          value={ formData.email }
                          placeholder="Enter email"
                          onChange={ handleInputChange } required/>
                      </div>

                      <div className="form-group">
                        <label>Phone Number</label>
                        <input
                          type="text"
                          name="phone"
                          value={ formData.phone }
                          placeholder="Enter phone number"
                          onChange={ handleInputChange } required/>
                      </div>

                      <div className={`form-group ${formData.subscriptionType === 'Individual' ? 'pb-10' : ''}`}>
                        <label>Subscription Type</label>
                        <select
                          className="custom-select-box"
                          name="subscriptionType"
                          value={ formData.subscriptionType }
                          onChange={ handleInputChange }
                          // defaultValue={``}
                        >
                          <option disabled={ true } value="">Choose Type</option>
                          <option value={ 'Individual' }>Individual</option>
                          <option value={ 'Corporate' }>Corporate</option>
                        </select>
                      </div>

                      {
                        formData.subscriptionType === 'Corporate'
                          ?
                            <>
                              <div className="form-group">
                                <label>Address</label>
                                <input
                                  type="text"
                                  name="address"
                                  value={ formData.address }
                                  placeholder="Enter an address"
                                  onChange={ handleInputChange } required/>
                              </div>

                              <div className={`form-group ${formData.subscriptionType === 'Corporate' ? 'pb-10' : ''}`}>
                                <label>Contact Person</label>
                                <input
                                  type="text"
                                  name="contact_person"
                                  value={ formData.contact_person }
                                  placeholder="Enter a Contact person"
                                  onChange={ handleInputChange } required/>
                              </div>
                            </>
                          :
                            <></>
                      }


                      {/* Form Group */ }
                      <div className="form-group">
                        <button
                          className="theme-btn btn-style-one"
                          type="submit"
                          data-kt-indicator={ isSubmitting ? 'on' : 'off' }
                          disabled={ isSubmitting ?? false }
                        >
                          <span className="indicator-label">Subscribe</span>
                          <span className="indicator-progress">
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ms-2"/>
                            </span>
                        </button>

                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/* Content Column */ }
            <div className="content-column col-lg-7 col-md-12 col-sm-12 px-sm-20">
              <div
                // className="inner-column"
              >
                <div className="sec-title" style={ { marginBottom: "10px" } }>
                  {/*<div className="title">why choose us</div>*/ }
                  <h2><span>Your Success <br/> </span>Our Reputation</h2>
                  <div className="text">Join us in embracing a hassle-free approach to document renewal management.
                  </div>
                </div>
                <ul className="list-style-one fw-bold">
                  <li>
                    <div className="inner-box">
                      <h5>Personalised reminders</h5>
                    </div>
                  </li>
                  <li>
                    <div className="inner-box">
                      <h5>Secure Data Management</h5>
                    </div>
                  </li>
                  <li>
                    <div className="inner-box">
                      <h5>Tailor made recommendations for renewals</h5>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}
