import React from 'react';

export const HomePageMap = () => {

  return (
    <>
      <div className="map-section">
        <div className="contact-map-area">
          <iframe
            className="contact-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2954.4660245193545!2d-83.0627114!3d42.2258559!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2e3480d42b5f%3A0x8cb32d4f31e70a5b!2s1646%20Maple%20Ave%2C%20Windsor%2C%20ON%20N9J%203L2%2C%20Canada!5e0!3m2!1sen!2sng!4v1703806293738!5m2!1sen!2sng"
            allowFullScreen=""
            aria-hidden="false"
            tabIndex={ 0 }
          />
        </div>
      </div>
    </>
  );
}
