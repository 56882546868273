import React from 'react';
import NavbarHeader from './NavbarHeader';

export const BannerSection = () => {

  return (
    <>
      <div className="banner-section" style={ { backgroundImage: 'url("assets/media/site-images/intro-1.webp")' } }>
        <NavbarHeader/>

        {/* Slide 1 */ }
        <div
          className="slide"
          // data-bg-image="assets/media/site-images/intro-1.webp"

        >
          <div className="auto-container w-100">
            <div className="row clearfix">

              <div className="col-lg-7 p-3 p-lg-5 pt-lg-3">
                <h1 className="display-4 fw-bold lh-1">Welcome to our Document Renewal Reminder System</h1>
                <p className="lead">DocRenew Immigration Consultancy also caters to other visitors to (and residents
                  of) Canada for work, study, or tourism and those reuniting with family.</p>
                <div className="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3">
                  <a href={ `#formSection` } className="theme-btn btn-style-three" type="submit" name="submit-form">
                    <span className="txt">Get Started</span>
                  </a>
                </div>
              </div>
              {/*<div className="col-lg-4 offset-lg-1 p-0 overflow-hidden shadow-lg">*/ }
              {/*  <img className="rounded-lg-3" src="assets/media/site-images/intro-1.webp" alt="" width="720"/>*/ }
              {/*</div>*/ }

            </div>

          </div>
        </div>


      </div>
    </>
  );
}
