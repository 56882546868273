import React, { useState, useEffect, useRef } from 'react';
import Swal from 'sweetalert2';
import logo from '../../logo.svg';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import api from '../../utils/api';
import {toast} from 'react-toastify';

const UserLogin = ({ userType, endpoint }) => {
  const navigate = useNavigate();
  const { token, login } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showLoginError, setShowLoginError] = useState(false);
  const [showLoginErrorMessage, setShowLoginErrorMessage] = useState('');

  // Refs for form and submit button
  const formRef = useRef(null);
  const submitButtonRef = useRef(null);

  useEffect(() => {
    if (token && userType) {
      navigate(`/${userType.toLowerCase()}`);
    }
  }, [token, userType, navigate]);

 const handleSubmit = async (e) => {
  e.preventDefault();
   setShowLoginError(false)
  submitButtonRef.current.setAttribute('data-kt-indicator', 'on');
  submitButtonRef.current.disabled = true;

  let formData = new FormData(formRef.current);

  try {
    const loginResponse = await api.post(`/authentication/${endpoint}/login`, formData);

    const responseData = {
      ...loginResponse.data,
      userType: userType
    };

    login(responseData);

    setTimeout(() => {
      setEmail('');
      setPassword('');
      navigate(`/${userType.toLowerCase()}`);
    }, 1500);
  }
  catch (e) {

  submitButtonRef.current.removeAttribute('data-kt-indicator');
  submitButtonRef.current.disabled = false;

  if (e.response) {
    const errorMessage = e.response.data.message;

    setShowLoginError(true);
    setShowLoginErrorMessage(errorMessage);

    toast.error(`${errorMessage}`);

    // await Swal.fire( {
    //   title: 'Error',
    //   text: errorMessage,
    //   allowOutsideClick: false,
    //   icon: 'error',
    //   showConfirmButton: true
    // } );
  } else {
    // Handle any other errors here
    console.error(e);
  }
}
};

  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-column flex-column-fluid">
          <div className="text-center mb-10 pt-10">
            <a href="/" className="mb-2">
              <img src={logo} alt="Logo" className="h-100px" />
            </a>
            <h4 className="text-dark">DocRenew</h4>
            <h6 className="text-dark">{ userType } Login</h6>
          </div>

          <div className="w-300px w-md-350px w-xl-350px bg-body rounded shadow-sm p-8 mx-auto">
            {showLoginError && (<div className="fw-bolder" style={{textAlign: "center", color: 'red'}}><small>{showLoginErrorMessage}</small></div>)}
            <form className="form w-100" noValidate="novalidate" ref={ formRef }>
              <div className="fv-row mb-5">
                <label className="form-label fw-bolder text-dark">Email</label>
                <input
                  id="email"
                  type="email"
                  className="form-control form-control-solid"
                  name="email"
                  value={ email }
                  onChange={ ( event ) => {
                    setEmail( event.target.value )
                    setShowLoginError(false)
                  } }
                  required=""
                  autoComplete="email"
                  autoFocus=""
                  placeholder="Enter Username/Email Here"
                />
              </div>
              <div className="fv-row mb-5">
                <div className="d-flex flex-stack mb-2">
                  <label id={ `alert` } className="form-label fw-bolder text-dark mb-0">
                    Password
                  </label>
                </div>
                <input
                  id="password"
                  type="password"
                  className="form-control form-control-solid"
                  name="password"
                  value={ password }
                  onChange={ ( event ) => {
                    setPassword( event.target.value )
                    setShowLoginError(false)
                  } }
                  required=""
                  autoComplete="off"
                  placeholder="Enter Password Here"
                />
              </div>
              <div className="text-center">
                <button
                  id="sign_in_submit"
                  ref={ submitButtonRef }
                  className="btn btn-lg btn-primary w-100 mb-5"
                  onClick={ handleSubmit }
                >
                  <span className="indicator-label">Log In</span>
                  <span className="indicator-progress">
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"/>
                  </span>
                </button>
              </div>
            </form>
          </div>
          <div className="d-flex flex-center flex-wrap fs-6 p-5 pb-10">
            <div className="d-flex flex-center fw-bold fs-6">
              <span className="text-muted">
                Powered by{ " " }
              </span>
              <a
                href="https://www.loftyedge.com"
                className="text-muted text-hover-primary px-2"
                target="_blank"
                rel="noreferrer"
              >
                LoftyEdge
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserLogin;
