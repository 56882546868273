import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

export const Contact = () => {

  let initialFormState = {
    name: '',
    email: '',
    phone: '',
    subscriptionType: 'Individual', // Default value
  };

  const [formData, setFormData] = useState(initialFormState);

  const handleFormSubmit = async (e) => {
    e.preventDefault();


    return false

    const eventButton = document.querySelector( '#registerSubmit' );
    const registerForm = document.querySelector( '#registerForm' );

    try {

      let data = JSON.stringify(formData);

      eventButton.setAttribute("data-kt-indicator", "on");
      eventButton.disabled = true;

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://api.docrenew.com/api/v1/submit-contact-form',
        headers: {
          'Content-Type': 'application/json'
        },
        data : data
      };

      // throw new Error('there was an error')

      await axios.request(config);

      setFormData(initialFormState);

      Swal.fire({
        title: "Your account has been created!",
        text: "Check your mail for login details.",
        allowOutsideClick: false,
        // toast: true,
        icon: "success",
        showConfirmButton: true,
        // timer: 2000,
        // timerProgressBar: true,
      });


    } catch ( e ) {

      // console.log(e);

      Swal.fire({
        title: "Error",
        text: e.response.data.message[0] ,
        allowOutsideClick: false,
        // toast: true,
        icon: "error",
        showConfirmButton: true,
        // timer: 2000,
        // timerProgressBar: true,
      });
    }
    finally {
      eventButton.removeAttribute( "data-kt-indicator");
      eventButton.disabled = false;
    }



  };

  return (
    <>
      <div
        id={ `contact` }
        className="contact-page-section mt-20"
        style={ {
          paddingBottom: "0px"
        } }
      >
        <div className="auto-container">
          {/* Contact Info Boxed */ }
          <div className="contact-info-boxed" style={ {
            paddingBottom: "10px"
          } }>

            <div className="sec-title centered">
              {/*<div className="title">contact us</div>*/ }
              <h2>
                We Are Always Here <span>To Help You</span>
              </h2>
            </div>

            <div className="row clearfix">
              {/* Column */ }
              <div className="column col-lg-6 col-md-6 col-sm-12">
                <h2>
                  LaSalle, <span>Canada</span>
                </h2>
                <div className="text fw-bolder">
                  1646 Maple Avenue LaSalle IN N9J 3L2
                </div>
                <div className="email">
                  Phone: <a href="tel:12269754491">+1 226-975-4491</a>
                </div>
                <div className="email">
                  Email: <a href="mailto:hello@docrenew.com">hello@docrenew.com</a>
                </div>
              </div>
              {/* Column */ }
              <div className="column col-lg-6 col-md-6 col-sm-12">
                {/*<div className="call">*/ }
                {/*  Call directly:*/ }
                {/*  <br />*/ }
                {/*  <a href="#">+1 212-226-3126</a>*/ }
                {/*</div>*/ }
                {/*<ul className="location-list">*/ }
                {/*  <li>*/ }
                {/*    <span>Brand Offices:</span>Allentown PA | Allanta, GA | Chicago,*/ }
                {/*    IL | Dallas, TX, <br /> Edison, NJ | Houston, TX*/ }
                {/*  </li>*/ }
                {/*  <li>*/ }
                {/*    <span>Work Hours:</span>Mon - Sat: 8.00 - 17.00, Sunday closed*/ }
                {/*  </li>*/ }
                {/*</ul>*/ }


                <div className="boxed-inner">
                  {/* Contact Form */ }
                  <div className="contact-form">
                    {/* Contact Form */ }
                    <form
                      id="contact-form"
                      onSubmit={ handleFormSubmit }
                    >
                      <div className="row clearfix">
                        <div className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <input
                            type="text"
                            name="name"
                            placeholder="Name *"
                            required=""
                          />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 form-group">
                          <input
                            type="email"
                            name="email"
                            placeholder="Emaill Address *"
                            required=""
                          />
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 form-group">
                          <input
                            type="text"
                            name="subject"
                            placeholder="Subject (Optional)"
                            required=""
                          />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <textarea
                            name="message"
                            placeholder="Message"
                            defaultValue={ "" }
                          />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 text-center form-group">
                          <button
                            className="theme-btn btn-style-one"
                            // type="submit"
                            // name="submit-form"
                          >
                            <span className="txt">Send Message</span>
                          </button>
                        </div>
                      </div>
                    </form>
                    <p className="form-messege"/>
                  </div>
                  {/*End Contact Form */ }
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
