import React from 'react';
import { Link } from 'react-router-dom';

const AdminTables = ({ title, showButton, buttonText, buttonAction, children }) => {
  return (
    <div className="card shadow-sm">
      <div className="card-header border-0">
        <div className="card-title">
          <h5 className={`fw-bold`}>{title}</h5>
        </div>
        <div className="card-toolbar m-0">
          {showButton &&
            <Link className="btn btn-sm btn-flex btn-light-primary fw-bold" onClick={buttonAction} to={`#`}>
              <i className="ki-duotone ki-plus-square fs-3">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
              </i> {buttonText}
            </Link>
          }
        </div>
      </div>
      <div className="card-body pt-0">
        <div className={`table-responsive`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default AdminTables;
