import React from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const NavbarHeader = () => {
  const navigate = useNavigate();
  const { token } = useAuth();

  const navigateToDashboard = ()=>{
    if ( token ) return navigate('/individual');
    if ( !token ) return navigate('/individual-login');
  }

  return (
    <>
      <nav className="app-container navbar navbar-expand-lg navbar-light pt-5"
           style={ { backgroundColor: 'rgba(255, 255, 255, 0.0)' } }>
        {/* Logo at the extreme left */ }
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">
            <img src="/path/to/your/logo.png" alt="Logo"/>
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                  aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Navigation links at the center */ }
          <div className="collapse navbar-collapse  justify-content-center" id="navbarNav">

            <ul
              className="navbar-nav ms-auto"

            >

              <li className="nav-item me-lg-10">
                <a
                  href="/public"
                  className="nav-link"
                  style={ {
                    position: 'relative',
                    display: 'block',
                    color: '#000000',
                    textAlign: 'center',
                    lineHeight: '30px',
                    textTransform: 'uppercase',
                    letterSpacing: '0px',
                    opacity: 1,
                    fontWeight: 600,
                    fontSize: '16px',
                    transition: 'all 500ms ease',
                    MozTransition: 'all 500ms ease',
                    WebkitTransition: 'all 500ms ease',
                    msTransition: 'all 500ms ease',
                    OTransition: 'all 500ms ease',
                  } }
                >
                  Home
                </a>
              </li>
              <li className="nav-item me-lg-10">
                <a
                  href="#about"
                  className="nav-link"
                  style={ {
                    position: 'relative',
                    display: 'block',
                    color: '#000000',
                    textAlign: 'center',
                    lineHeight: '30px',
                    textTransform: 'uppercase',
                    letterSpacing: '0px',
                    opacity: 1,
                    fontWeight: 600,
                    fontSize: '16px',
                    transition: 'all 500ms ease',
                    MozTransition: 'all 500ms ease',
                    WebkitTransition: 'all 500ms ease',
                    msTransition: 'all 500ms ease',
                    OTransition: 'all 500ms ease',
                  } }
                >
                  About
                </a>
              </li>
              <li className="nav-item me-lg-10">
                <a
                  href="#plans"
                  className="nav-link"
                  style={ {
                    position: 'relative',
                    display: 'block',
                    color: '#000000',
                    textAlign: 'center',
                    lineHeight: '30px',
                    textTransform: 'uppercase',
                    letterSpacing: '0px',
                    opacity: 1,
                    fontWeight: 600,
                    fontSize: '16px',
                    transition: 'all 500ms ease',
                    MozTransition: 'all 500ms ease',
                    WebkitTransition: 'all 500ms ease',
                    msTransition: 'all 500ms ease',
                    OTransition: 'all 500ms ease',
                  } }
                >
                  Plans
                </a>
              </li>
              <li className="nav-item me-lg-10">
                <a
                  href="#faq"
                  className="nav-link"
                  style={ {
                    position: 'relative',
                    display: 'block',
                    color: '#000000',
                    textAlign: 'center',
                    lineHeight: '30px',
                    textTransform: 'uppercase',
                    letterSpacing: '0px',
                    opacity: 1,
                    fontWeight: 600,
                    fontSize: '16px',
                    transition: 'all 500ms ease',
                    MozTransition: 'all 500ms ease',
                    WebkitTransition: 'all 500ms ease',
                    msTransition: 'all 500ms ease',
                    OTransition: 'all 500ms ease',
                  } }
                >
                  FAQs
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#contact"
                  className="nav-link"
                  style={ {
                    position: 'relative',
                    display: 'block',
                    color: '#000000',
                    textAlign: 'center',
                    lineHeight: '30px',
                    textTransform: 'uppercase',
                    letterSpacing: '0px',
                    opacity: 1,
                    fontWeight: 600,
                    fontSize: '16px',
                    transition: 'all 500ms ease',
                    MozTransition: 'all 500ms ease',
                    WebkitTransition: 'all 500ms ease',
                    msTransition: 'all 500ms ease',
                    OTransition: 'all 500ms ease',
                  } }
                >
                  Contact Us
                </a>
              </li>
              {/* Add more links as needed */ }

            </ul>


            {/* Log In button at the extreme right */ }
            <div className="navbar-nav ms-auto login-btn text-center">

              <a href={ `` } className="theme-btn btn-style-three" onClick={ navigateToDashboard }>
                <span className="txt">Log In</span>
              </a>
            </div>
          </div>


        </div>
      </nav>
    </>

  )
    ;
};

export default NavbarHeader;
