import React from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import { NotFound } from './pages/NotFound';
import PrivateRoute from './components/AdminPage/PrivateRoute';
import AdminPage from './pages/AdminPage';
import IndividualPage from './pages/IndividualPage';
import CorporatePage from './pages/CorporatePage';
import AdminLogin from './pages/AdminLogin';
import IndividualLogin from './pages/IndividualLogin';
import CorporateLogin from './pages/CorporateLogin';
import {ToastContainer} from 'react-toastify';

function App() {
  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/404" element={<NotFound />} />

          <Route path="/individual-login" element={<IndividualLogin />} />
          <Route path="/corporate-login" element={<CorporateLogin />} />
          <Route path="/admin-login" element={<AdminLogin />} />

          <Route path="/individual" element={<PrivateRoute element={<IndividualPage />} allowedUserTypes={['Individual']} />} />
          <Route path="/corporate" element={<PrivateRoute element={<CorporatePage />} allowedUserTypes={['Corporate']} />} />
          <Route path="/admin" element={<PrivateRoute element={<AdminPage />} allowedUserTypes={['Admin']} />} />

          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
